<template>
  <div class="popular-authors">
    <div class="bestAuthorsPosts">
      <div v-if="authors.length > 0" class="author-container">
        <div
            v-for="(author, index) in authors.slice(0, maxVisibleAuthors)"
            :key="index"
            class="author-post"
            @mouseover="hoverAuthor = author"
            @mouseleave="hoverAuthor = null"
            @click="goToAuthorPage(author.pseudonym)"
        >
          <div class="author-square">
            <div class="background-overlay"></div>
            <div class="image-container">
              <img :src="author.authorPhotoTempUrl" alt="Популярные авторы не загружены!" class="centered-image" />
            </div>
            <div v-if="hoverAuthor === author" class="eighteen-font-400w author-description">
              <div class="eighteen-font-400w author-name">{{ author.pseudonym }}</div>
              <div class="eighteen-font-400w description">{{ author.description }}</div>
            </div>
          </div>
        </div>
        <div class="author-post all-authors">
          <div class="all-authors-content">
            <QuarterSquare label="Все исполнители" />
          </div>
        </div>
      </div>
      <p v-else>Загрузка авторов...</p>
    </div>
  </div>
</template>

<script lang="ts">
import {ref, onMounted, Ref} from 'vue';
import QuarterSquare from '@/components/common/QuarterSquare.vue';
import authorApi from '@/api/AuthorApi';
import { useRouter } from 'vue-router';

export default {
  components: {
    QuarterSquare,
  },
  setup() {
    const maxVisibleAuthors = ref<number>(7);
    const hoverAuthor = ref<BackendT['AuthorInfoResponse'] | null>(null) as unknown as Ref<BackendT['AuthorInfoResponse'] | null>;
    const authors = ref<BackendT['AuthorInfoResponse'][]>([]);
    const router = useRouter();

    const goToAuthorPage = (pseudonym: string | null) => {
      if (!pseudonym) {
        console.error('Псевдоним автора отсутствует.');
        return;
      }

      router.push({
        name: 'authorProfile',
        params: { pseudonym },
      });
    };

    onMounted(async () => {
      try {
        authors.value = await authorApi.fetchAuthorInfoByPopularity(7);
      } catch (error) {
        console.error('Ошибка при загрузке популярных авторов:', error);
      }
    });

    return {
      maxVisibleAuthors,
      hoverAuthor,
      authors,
      goToAuthorPage,
    };
  },
};
</script>

<style scoped>
.popular-authors {
  width: 100%;
}

.bestAuthorsPosts {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.author-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 0;
  width: 100%;
  box-sizing: border-box;
}

.author-post {
  position: relative;
  aspect-ratio: 1;
  border: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: background-color 0.5s ease;
  cursor: pointer;
}

.author-square {
  position: relative;
  width: 100%;
  height: 100%;
}

.background-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ff7e07;
  transform: scale(0);
  transform-origin: center;
  transition: transform 0.7s cubic-bezier(0.25, 0.1, 0.25, 1);
}

.author-post:hover .background-overlay {
  transform: scale(1);
}

.image-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.centered-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30%;
  height: 30%;
  border-radius: 50%;
  object-fit: cover;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.author-post:hover .centered-image {
  transform: translate(-50%, -50%) scale(0.3);
  opacity: 0;
}

.author-description {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  text-align: left;
  color: black;
}

.author-name {
  font-weight: bold;
  color: black;
}

.description {
  margin-top: 10px;
  color: black;
  line-height: 1.5;
}

.author-post:hover .author-description {
  transform: translate(-50%, -50%) scale(1);
}

.author-post:hover {
  background-color: rgba(255, 126, 7, 1);
}

.all-authors {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.all-authors-content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
</style>