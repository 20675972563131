import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "image-upload-card" }
const _hoisted_2 = { class: "content-container" }
const _hoisted_3 = { class: "left-section" }
const _hoisted_4 = {
  key: 0,
  class: "plus-sign"
}
const _hoisted_5 = {
  key: 1,
  class: "image-container"
}
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "right-section" }
const _hoisted_8 = { class: "slider-container" }

import { ref, computed, watch, nextTick } from 'vue';

const placeholderImage = '/logo.ico';

export default /*@__PURE__*/_defineComponent({
  __name: 'ImageUploadComponent',
  props: {
  modelValue: File,
  title: String,
  description: String
},
  emits: ["update:modelValue"],
  setup(__props, { emit: __emit }) {

const props = __props;
const emit = __emit;

const fileInput = ref<HTMLInputElement | null>(null);
const imageFile = ref<File | null>(props.modelValue || null);
const imageScale = ref(1);
const imageRotation = ref(0);
const imageElement = ref<HTMLImageElement | null>(null);

const imageFileUrl = ref<string>(placeholderImage);

watch(imageFile, (newFile, oldFile) => {
  if (oldFile && imageFileUrl.value.startsWith('blob:')) {
    URL.revokeObjectURL(imageFileUrl.value);
  }
  imageFileUrl.value = newFile ? URL.createObjectURL(newFile) : placeholderImage;
});

const triggerFileInput = () => {
  fileInput.value?.click();
};

const onImageChange = (event: Event) => {
  const input = event.target as HTMLInputElement;
  const file = input.files?.[0];
  if (file) {
    imageFile.value = file;
    emit("update:modelValue", file);
    emitModifiedImage();
  }
};

const removeImage = () => {
  imageFile.value = null;
  imageScale.value = 1;
  imageRotation.value = 0;
  emit("update:modelValue", null);
};

const rotateLeft = () => {
  imageRotation.value -= 90;
  emitModifiedImage();
};

const rotateRight = () => {
  imageRotation.value += 90;
  emitModifiedImage();
};

const resizeImage = () => {
  emitModifiedImage();
};

const emitModifiedImage = () => {
  if (!imageFile.value) {
    emit("update:modelValue", null);
    return;
  }

  const img = new Image();
  const reader = new FileReader();
  reader.readAsDataURL(imageFile.value);

  reader.onload = () => {
    img.src = reader.result as string;
    img.onload = () => {
      const containerSize = 200;
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      if (!ctx) return;

      canvas.width = containerSize;
      canvas.height = containerSize;
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      const aspectRatio = img.naturalWidth / img.naturalHeight;
      let drawWidth, drawHeight;

      if (aspectRatio > 1) {
        drawWidth = containerSize * imageScale.value;
        drawHeight = drawWidth / aspectRatio;
      } else {
        drawHeight = containerSize * imageScale.value;
        drawWidth = drawHeight * aspectRatio;
      }

      ctx.translate(containerSize / 2, containerSize / 2);
      ctx.rotate((imageRotation.value * Math.PI) / 180);
      ctx.drawImage(img, -drawWidth / 2, -drawHeight / 2, drawWidth, drawHeight);

      canvas.toBlob((blob) => {
        if (blob) {
          const modifiedFile = new File([blob], imageFile.value!.name, {
            type: imageFile.value!.type,
          });
          emit("update:modelValue", modifiedFile);
        }
      }, imageFile.value!.type);
    };
  };

  img.onerror = () => {
    console.error("Ошибка загрузки изображения!");
  };
};

const imageStyle = computed(() => {
  return {
    transform: `scale(${imageScale.value}) rotate(${imageRotation.value}deg)`,
  };
});

watch([imageFile, imageScale, imageRotation], async () => {
  await nextTick();
  emitModifiedImage();
}, { flush: 'post' });


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          class: "upload-area",
          onClick: triggerFileInput
        }, [
          _createElementVNode("input", {
            type: "file",
            accept: "image/png, image/jpeg",
            onChange: onImageChange,
            ref_key: "fileInput",
            ref: fileInput,
            hidden: ""
          }, null, 544),
          (!imageFile.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, "+"))
            : _createCommentVNode("", true),
          (imageFile.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("img", {
                  src: imageFileUrl.value,
                  class: "image-preview",
                  style: _normalizeStyle(imageStyle.value),
                  ref_key: "imageElement",
                  ref: imageElement
                }, null, 12, _hoisted_6)
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _cache[4] || (_cache[4] = _createElementVNode("div", { class: "title" }, "Обложка песни", -1)),
        _cache[5] || (_cache[5] = _createElementVNode("p", { class: "description" }, [
          _createTextVNode("*Добавьте или перетащите"),
          _createElementVNode("br"),
          _createTextVNode("*Максимальное разрешение: 1500 x 1500"),
          _createElementVNode("br"),
          _createTextVNode("*Формат: PNG, JPEG, JPG")
        ], -1)),
        _createElementVNode("div", { class: "controls" }, [
          _createElementVNode("button", {
            onClick: removeImage,
            class: "icon-button"
          }, _cache[1] || (_cache[1] = [
            _createElementVNode("i", { class: "trash-icon" }, null, -1)
          ])),
          _createElementVNode("button", {
            onClick: rotateLeft,
            class: "icon-button"
          }, _cache[2] || (_cache[2] = [
            _createElementVNode("i", { class: "rotate-left-icon" }, null, -1)
          ])),
          _createElementVNode("button", {
            onClick: rotateRight,
            class: "icon-button"
          }, _cache[3] || (_cache[3] = [
            _createElementVNode("i", { class: "rotate-right-icon" }, null, -1)
          ]))
        ]),
        _createElementVNode("div", _hoisted_8, [
          _withDirectives(_createElementVNode("input", {
            type: "range",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((imageScale).value = $event)),
            min: "1",
            max: "2",
            step: "0.05",
            onInput: resizeImage
          }, null, 544), [
            [_vModelText, imageScale.value]
          ])
        ])
      ])
    ])
  ]))
}
}

})