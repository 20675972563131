import {BaseApi} from "@/api/base/BaseApi";

const API_PREFIX = '/backend'

export class AlbumApi {
    private client: BaseApi;

    constructor() {
        this.client = new BaseApi();
    }

    public async saveAlbum(requestBody: { photo: File; album: Omit<BackendT["AlbumSavingRequest"], 'photo'>; }): Promise<BackendT["AlbumResponse"]> {
        try {
            const formData = new FormData();
            formData.append('photo', requestBody.photo, 'photo.jpg');
            formData.append('album', new Blob([JSON.stringify(requestBody.album)], { type: 'application/json' }));

            return this.client.post<BackendT["AlbumResponse"]>(API_PREFIX + '/album', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
        } catch (error) {
            throw new Error(`Failed to save Album: ${error}`);
        }
    }

    public async fetchAlbumsByNameFirstLetters(albumNameFirstLetters: string, limit: number): Promise<BackendT["AlbumDynamicPreviewResponse"][]> {
        try {
            return this.client.get<BackendT["AlbumDynamicPreviewResponse"][]>(API_PREFIX + `/album/by/name/${albumNameFirstLetters}`, {
                params: {limit},
                headers: {
                    'Content-Type': 'application/json',
                }
            });
        } catch (error) {
            throw new Error(`An error occurred while fetching albums by name first letters: ${error}`);
        }
    }

    async fetchAlbumsByAuthorAndNameFirstLetters(authorPseudonym: string, nameFirstLetters: string): Promise<BackendT["AlbumDynamicPreviewResponse"][]> {
        try {
            return this.client.get<BackendT["AlbumDynamicPreviewResponse"][]>(API_PREFIX + `/album/by/author/${authorPseudonym}/name/${nameFirstLetters}`, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
        } catch (error) {
            throw new Error(`An error occurred while fetching albums by author: ${error}`);
        }
    }
}

export default new AlbumApi();
