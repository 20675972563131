import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "modal-container" }
const _hoisted_2 = ["value"]

import { onMounted, ref } from "vue";
import DatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import ImageUploadComponent from "@/components/common/ImageUploadComponent.vue";
import authorApi from "@/api/AuthorApi";
import {
  authorsSearchQuery,
  closeAuthorModal,
  errorMessage,
  isAuthorModalOpen,
  newAlbum,
  newAuthor,
  newSong,
  safeYearsOfActivity,
  selectedAuthor,
  showError,
  showSuccess,
  successMessage
} from "@/components/add-song/modal/AddSongFormStates";
import { AxiosError } from "axios";


export default /*@__PURE__*/_defineComponent({
  __name: 'AuthorModalForm',
  setup(__props) {

let countries: string[] = [];

onMounted(async () => {
  await fetchCountries();
});

const fetchCountries = async () => {
  try {
    const response = await authorApi.fetchAuthorCountries();
    console.log('Загруженные страны:', response);
    countries = response || [];
  } catch (error) {
    console.error('Ошибка при загрузке списка стран:', error);
  }
};

const saveAuthor = async () => {
  try {
    console.log("Отправка данных автора:", newAuthor.value);

    const response = await authorApi.saveAuthor(newAuthor.value);
    if (response && response.pseudonym) {
      authorsSearchQuery.value = response.pseudonym;
      newAlbum.value.album.authorPseudonym = response.pseudonym;
      selectedAuthor.value = { id: response.id, pseudonym: response.pseudonym };
      newSong.value.song.authorPseudonym = response.pseudonym;
    }
    successMessage.value = "Автор успешно создан";
    showSuccess.value = true;
    closeAuthorModal();
  } catch (error) {
    const axiosError = error as AxiosError<ApiErrorResponse>;
    errorMessage.value = axiosError.response?.data?.message || "Ошибка при создании автора.";
    showError.value = true;
    console.error("Ошибка при создании автора:", error);
  }
};

return (_ctx: any,_cache: any) => {
  return (_unref(isAuthorModalOpen))
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "modal-overlay",
        onClick: _cache[8] || (_cache[8] = _withModifiers(
//@ts-ignore
(...args) => (_unref(closeAuthorModal) && _unref(closeAuthorModal)(...args)), ["self"]))
      }, [
        _createElementVNode("div", _hoisted_1, [
          _cache[10] || (_cache[10] = _createElementVNode("div", { class: "modal-title" }, "Создание нового автора", -1)),
          _createElementVNode("form", {
            onSubmit: _withModifiers(saveAuthor, ["prevent"]),
            class: "modal-form"
          }, [
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(newAuthor).author.name) = $event)),
              placeholder: "Имя исполнителя",
              class: "input-field"
            }, null, 512), [
              [_vModelText, _unref(newAuthor).author.name]
            ]),
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(newAuthor).author.pseudonym) = $event)),
              placeholder: "Псевдоним",
              class: "input-field"
            }, null, 512), [
              [_vModelText, _unref(newAuthor).author.pseudonym]
            ]),
            _createVNode(_unref(DatePicker), {
              modelValue: _unref(safeYearsOfActivity).start,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(safeYearsOfActivity).start) = $event)),
              placeholder: "Начало активности",
              class: "input-field"
            }, null, 8, ["modelValue"]),
            _createVNode(_unref(DatePicker), {
              modelValue: _unref(safeYearsOfActivity).end,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(safeYearsOfActivity).end) = $event)),
              placeholder: "Конец активности",
              class: "input-field"
            }, null, 8, ["modelValue"]),
            _createVNode(_unref(DatePicker), {
              modelValue: _unref(newAuthor).author.birthDate,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(newAuthor).author.birthDate) = $event)),
              placeholder: "Дата рождения",
              class: "input-field"
            }, null, 8, ["modelValue"]),
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_unref(newAuthor).author.description) = $event)),
              placeholder: "Описание",
              class: "input-field"
            }, null, 512), [
              [_vModelText, _unref(newAuthor).author.description]
            ]),
            _withDirectives(_createElementVNode("select", {
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_unref(newAuthor).author.country) = $event)),
              class: "input-field"
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(countries), (country) => {
                return (_openBlock(), _createElementBlock("option", {
                  key: country,
                  value: country
                }, _toDisplayString(country), 9, _hoisted_2))
              }), 128))
            ], 512), [
              [_vModelSelect, _unref(newAuthor).author.country]
            ]),
            _createVNode(ImageUploadComponent, {
              title: 'Фото автора',
              description: 'Максимальное разрешение: 1500 x 1500. Формат: PNG, JPEG, JPG.',
              modelValue: _unref(newAuthor).photo,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_unref(newAuthor).photo) = $event))
            }, null, 8, ["modelValue"]),
            _cache[9] || (_cache[9] = _createElementVNode("button", {
              type: "submit",
              class: "save-button"
            }, "Сохранить", -1))
          ], 32)
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})