<template>
  <div v-if="isAuthorModalOpen" class="modal-overlay" @click.self="closeAuthorModal">
    <div class="modal-container">
      <div class="modal-title">Создание нового автора</div>
      <form @submit.prevent="saveAuthor" class="modal-form">
        <input v-model="newAuthor.author.name" placeholder="Имя исполнителя" class="input-field" />
        <input v-model="newAuthor.author.pseudonym" placeholder="Псевдоним" class="input-field" />

        <DatePicker v-model="safeYearsOfActivity.start" placeholder="Начало активности" class="input-field" />
        <DatePicker v-model="safeYearsOfActivity.end" placeholder="Конец активности" class="input-field" />
        <DatePicker v-model="newAuthor.author.birthDate" placeholder="Дата рождения" class="input-field" />

        <input v-model="newAuthor.author.description" placeholder="Описание" class="input-field" />

        <select v-model="newAuthor.author.country" class="input-field">
          <option v-for="country in countries" :key="country" :value="country">
            {{ country }}
          </option>
        </select>

        <ImageUploadComponent
            :title="'Фото автора'"
            :description="'Максимальное разрешение: 1500 x 1500. Формат: PNG, JPEG, JPG.'"
            v-model="newAuthor.photo"
        />

        <button type="submit" class="save-button">Сохранить</button>
      </form>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import DatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import ImageUploadComponent from "@/components/common/ImageUploadComponent.vue";
import authorApi from "@/api/AuthorApi";
import {
  authorsSearchQuery,
  closeAuthorModal,
  errorMessage,
  isAuthorModalOpen,
  newAlbum,
  newAuthor,
  newSong,
  safeYearsOfActivity,
  selectedAuthor,
  showError,
  showSuccess,
  successMessage
} from "@/components/add-song/modal/AddSongFormStates";
import { AxiosError } from "axios";

let countries: string[] = [];

onMounted(async () => {
  await fetchCountries();
});

const fetchCountries = async () => {
  try {
    const response = await authorApi.fetchAuthorCountries();
    console.log('Загруженные страны:', response);
    countries = response || [];
  } catch (error) {
    console.error('Ошибка при загрузке списка стран:', error);
  }
};

const saveAuthor = async () => {
  try {
    console.log("Отправка данных автора:", newAuthor.value);

    const response = await authorApi.saveAuthor(newAuthor.value);
    if (response && response.pseudonym) {
      authorsSearchQuery.value = response.pseudonym;
      newAlbum.value.album.authorPseudonym = response.pseudonym;
      selectedAuthor.value = { id: response.id, pseudonym: response.pseudonym };
      newSong.value.song.authorPseudonym = response.pseudonym;
    }
    successMessage.value = "Автор успешно создан";
    showSuccess.value = true;
    closeAuthorModal();
  } catch (error) {
    const axiosError = error as AxiosError<ApiErrorResponse>;
    errorMessage.value = axiosError.response?.data?.message || "Ошибка при создании автора.";
    showError.value = true;
    console.error("Ошибка при создании автора:", error);
  }
};
</script>



<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-container {
  background-color: #121212;
  padding: 20px;
  border-radius: 10px;
  width: 40%;
  text-align: center;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.modal-title {
  font-size: 1.5rem;
  margin-bottom: 15px;
  color: white;
}

.input-field {
  width: 100%;
  padding: 12px;
  background-color: #2c2c2c;
  border: 1px solid #444;
  border-radius: 4px;
  color: white;
  font-size: 16px;
  margin: 10px 0;
}

.save-button, .close-button {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.save-button {
  background-color: #ff6600;
  color: white;
}

.close-button {
  background-color: #6c757d;
  color: white;
}

.save-button:hover {
  background-color: #e65c00;
}

.close-button:hover {
  background-color: #5a6268;
}
</style>