import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, vModelDynamic as _vModelDynamic, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, withModifiers as _withModifiers, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "form-group" }
const _hoisted_2 = { class: "form-group" }
const _hoisted_3 = { class: "form-group" }
const _hoisted_4 = { class: "form-group" }
const _hoisted_5 = { class: "form-group" }
const _hoisted_6 = { class: "password-container" }
const _hoisted_7 = ["type"]
const _hoisted_8 = { class: "form-group" }
const _hoisted_9 = ["type"]
const _hoisted_10 = {
  key: 0,
  class: "error-message"
}
const _hoisted_11 = { class: "form-group" }
const _hoisted_12 = { class: "form-group" }
const _hoisted_13 = { class: "form-group" }
const _hoisted_14 = ["disabled"]
const _hoisted_15 = { class: "login-link" }
const _hoisted_16 = { class: "eighteen-font-400w" }

import { ref, computed } from 'vue';
import { AxiosError } from "axios";
import router from "@/router/router";
import { CookieManager } from "@/api/base/CookieManager";
import Snackbar from "@/components/common/Snackbar.vue";
import {useAuthStore} from "@/store/authStore";
import authApi from "@/api/AuthApi";


export default /*@__PURE__*/_defineComponent({
  __name: 'RegisterForm',
  setup(__props) {

const form = ref({
  firstname: '',
  lastname: '',
  surname: '',
  username: '',
  email: '',
  phone: '',
  password: '',
  confirmPassword: '',
});

const photo = ref<File | null>(null);
const showPassword = ref(false);
const showError = ref(false);
const showSuccess = ref(false);
const errorMessage = ref('');
const successMessage = ref('');
const authStore = useAuthStore();

const handlePhotoChange = (event: Event) => {
  const target = event.target as HTMLInputElement;
  if (target.files) {
    photo.value = target.files[0];
  }
};

const passwordMismatch = computed(() => form.value.password !== form.value.confirmPassword);

const togglePassword = () => {
  showPassword.value = !showPassword.value;
};

const register = async () => {
  if (!photo.value) {
    errorMessage.value = 'Пожалуйста, добавьте фотографию.';
    showError.value = true;
    return;
  }

  if (passwordMismatch.value) return;

  const requestData = {
    photo: photo.value,
    user: {
      firstname: form.value.firstname,
      lastname: form.value.lastname,
      surname: form.value.surname,
      username: form.value.username,
      email: form.value.email,
      phone: form.value.phone,
      password: form.value.password,
      confirmPassword: form.value.confirmPassword,
    },
  };

  try {
    const response = await authApi.signUpUser(requestData);
    if (response.token) {
      CookieManager.setCookie('token', response.token);
      successMessage.value = 'Регистрация прошла успешно';
      showSuccess.value = true;

      // Обновляем состояние аутентификации
      authStore.checkSession();

      setTimeout(() => {
        router.push({ name: 'home' });
      }, 1000);
    }
  } catch (error: unknown) {
    const axiosError = error as AxiosError<ApiErrorResponse>;
    errorMessage.value = axiosError.response?.data?.message || 'Неизвестная ошибка. Попробуйте снова.';
    showError.value = true;
  }
};

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[13] || (_cache[13] = _createElementVNode("div", null, "Пожалуйста, заполните необходимые поля для создания нового профиля", -1)),
    _createVNode(Snackbar, {
      color: "red",
      show: showError.value,
      "onUpdate:show": _cache[0] || (_cache[0] = ($event: any) => ((showError).value = $event)),
      message: errorMessage.value
    }, null, 8, ["show", "message"]),
    _createVNode(Snackbar, {
      color: "green",
      show: showSuccess.value,
      "onUpdate:show": _cache[1] || (_cache[1] = ($event: any) => ((showSuccess).value = $event)),
      message: successMessage.value
    }, null, 8, ["show", "message"]),
    _createElementVNode("form", {
      onSubmit: _withModifiers(register, ["prevent"])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _withDirectives(_createElementVNode("input", {
          type: "text",
          id: "firstname",
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((form.value.firstname) = $event)),
          placeholder: "Имя"
        }, null, 512), [
          [_vModelText, form.value.firstname]
        ])
      ]),
      _createElementVNode("div", _hoisted_2, [
        _withDirectives(_createElementVNode("input", {
          type: "text",
          id: "lastname",
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((form.value.lastname) = $event)),
          placeholder: "Фамилия"
        }, null, 512), [
          [_vModelText, form.value.lastname]
        ])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _withDirectives(_createElementVNode("input", {
          type: "text",
          id: "surname",
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((form.value.surname) = $event)),
          placeholder: "Отчество"
        }, null, 512), [
          [_vModelText, form.value.surname]
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _withDirectives(_createElementVNode("input", {
          type: "text",
          id: "username",
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((form.value.username) = $event)),
          placeholder: "Имя пользователя",
          required: ""
        }, null, 512), [
          [_vModelText, form.value.username]
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _withDirectives(_createElementVNode("input", {
            type: showPassword.value ? 'text' : 'password',
            id: "password",
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((form.value.password) = $event)),
            placeholder: "Пароль",
            required: ""
          }, null, 8, _hoisted_7), [
            [_vModelDynamic, form.value.password]
          ]),
          _createElementVNode("div", {
            class: "eye-container",
            onClick: togglePassword
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(['eye', showPassword.value ? 'eye-closed' : 'eye-open'])
            }, null, 2)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _withDirectives(_createElementVNode("input", {
          type: showPassword.value ? 'text' : 'password',
          id: "confirmPassword",
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((form.value.confirmPassword) = $event)),
          placeholder: "Подтвердить пароль",
          required: ""
        }, null, 8, _hoisted_9), [
          [_vModelDynamic, form.value.confirmPassword]
        ])
      ]),
      (passwordMismatch.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, "Пароли не совпадают"))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_11, [
        _withDirectives(_createElementVNode("input", {
          type: "email",
          id: "email",
          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((form.value.email) = $event)),
          placeholder: "Email"
        }, null, 512), [
          [_vModelText, form.value.email]
        ])
      ]),
      _createElementVNode("div", _hoisted_12, [
        _withDirectives(_createElementVNode("input", {
          type: "text",
          id: "phone",
          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((form.value.phone) = $event)),
          placeholder: "Телефон"
        }, null, 512), [
          [_vModelText, form.value.phone]
        ])
      ]),
      _createElementVNode("div", _hoisted_13, [
        _cache[10] || (_cache[10] = _createElementVNode("label", {
          for: "photo",
          class: "file-label"
        }, "Выберите фотографию", -1)),
        _createElementVNode("input", {
          type: "file",
          id: "photo",
          onChange: handlePhotoChange,
          accept: "image/*",
          class: "file-input"
        }, null, 32)
      ]),
      _createElementVNode("button", {
        type: "submit",
        class: "primary-button",
        disabled: passwordMismatch.value
      }, "Зарегистрироваться", 8, _hoisted_14),
      _createElementVNode("div", _hoisted_15, [
        _createElementVNode("div", _hoisted_16, [
          _cache[12] || (_cache[12] = _createTextVNode("Уже зарегистрированы? ")),
          _createVNode(_component_router_link, {
            to: "/login",
            class: "login-link-text"
          }, {
            default: _withCtx(() => _cache[11] || (_cache[11] = [
              _createTextVNode("Войти")
            ])),
            _: 1
          })
        ])
      ])
    ], 32)
  ], 64))
}
}

})