<template>
  <div class="profile-container">
    <div class="main-container">
      <div class="left-column">
        <div class="profile-photo">
          <img :src="authorInfo.authorPhotoTempUrl || '/default-avatar.png'" alt="Author Photo"/>
          <button @click="addToFavorites" class="favorite-button eighteen-font-400w">В избранное</button>
        </div>
      </div>

      <div class="right-column">
        <div class="profile-info">
          <div class="profile-row eighteen-font-500w"><span class="label">Псевдоним:</span>
            <span>{{ authorInfo.pseudonym || 'Не указан' }}</span></div>
          <div class="profile-row eighteen-font-500w"><span class="label">Имя:</span>
            <span>{{ authorInfo.name || 'Не указано' }}</span></div>
          <div class="profile-row eighteen-font-500w"><span class="label">Возраст:</span> <span
              class="age">{{ authorInfo.age || 'Не указан' }}</span></div>
          <div class="profile-row eighteen-font-500w"><span class="label">Рейтинг:</span> <span
              class="rating">{{ authorInfo.rating || 'Не указан' }}</span></div>
        </div>

        <div class="bottom-section">
          <div class="about-author">
            <div class="eighteen-font-400w">Об исполнителе</div>
            <span class="description">{{ authorInfo.description || 'Нет информации' }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="country-overlay">{{ authorInfo.country || '' }}</div>
  </div>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref} from 'vue';
import authorApi from "@/api/AuthorApi";
import {useRoute} from 'vue-router';

export default defineComponent({
  setup() {
    const authorInfo = ref<BackendT['AuthorResponse']>({
      id: 0,
      pseudonym: '',
      name: '',
      description: '',
      country: '',
      age: 0,
      rating: 0,
      authorPhotoTempUrl: '',
    });

    const route = useRoute();

    const fetchAuthorInfo = async () => {
      try {
        const pseudonym = route.params.pseudonym as string;
        if (pseudonym) {
          authorInfo.value = await authorApi.fetchAuthorByPseudonym(pseudonym);
        } else {
          console.error('Псевдоним автора отсутствует в параметрах маршрута.');
        }
      } catch (error) {
        console.error('Ошибка загрузки данных автора:', error);
      }
    };

    const addToFavorites = () => {
      console.log('Добавить в избранное');
    };

    onMounted(() => {
      fetchAuthorInfo();
    });

    return {
      authorInfo,
      addToFavorites,
    };
  },
});
</script>

<style scoped>
.profile-container {
  color: white;
  padding: 2%;
  font-family: Arial, sans-serif;
  position: relative;
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
}

.main-container {
  display: flex;
  gap: 0;
  margin-bottom: 4%;
  border: 1px solid rgba(255, 255, 255, 0.1);
  height: 50vh;
  box-sizing: border-box;
}

.left-column {
  width: 25%;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 2%;
  box-sizing: border-box;
}

.profile-photo {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.profile-photo img {
  border-radius: 50%;
  width: 100%;
  max-width: 20vw;
  height: auto;
  aspect-ratio: 1 / 1;
  object-fit: cover;
}

.favorite-button {
  padding: 1% 2%;
  background-color: transparent;
  border: 2px solid #FF9900;
  color: #FF9900;
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;
  width: 100%;
  max-width: 15vw;
  margin-top: 2%;
  box-sizing: border-box;
}

.favorite-button:hover {
  background-color: #FF9900;
  color: white;
}

.right-column {
  width: 75%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.profile-info {
  height: 20vh;
  padding: 2%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  overflow: auto;
}

.profile-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.3%;
}

.label {
  color: #aaa;
}

.age {
  color: #FF9900;
  font-weight: bold;
}

.rating {
  color: #1E90FF;
  font-weight: bold;
}

.bottom-section {
  flex: 1;
  display: flex;
  box-sizing: border-box;
}

.about-author {
  width: 100%;
  padding: 2%;
  box-sizing: border-box;
  overflow: auto;
  position: relative;
}

.about-author h2 {
  margin: 0;
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: clamp(16px, 2vw, 18px);
}

.description {
  font-size: clamp(14px, 2vw, 16px);
  color: #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.country-overlay {
  position: absolute;
  top: 30%;
  right: 30%;
  z-index: -1;
  font-size: clamp(80px, 15vw, 160px);
  font-weight: bold;
  color: rgba(255, 255, 255, 0.05);
}
</style>