<template>
  <div class="search-container">
    <div class="filters">
      <div class="search-box">
        <input v-model="searchQuery" type="text" placeholder="Поиск" class="search-input"/>
      </div>
      <Dropdown v-model="selectedGenre" :options="genres.map(g => ({ label: g, value: g }))" placeholder="Жанр" />
      <Dropdown v-model="selectedLanguage" :options="languages.map(l => ({ label: l, value: l }))" placeholder="Язык" />
      <Dropdown v-model="selectedDifficulty" :options="difficultyOptions" placeholder="Сложность">
        <!-- Используем слот для кастомного рендеринга опций -->
        <template #option="{ option }">
          <StarRating :rating="getStarCount(option.stars)" />
        </template>
      </Dropdown>
    </div>

    <div class="table-container">
      <div class="table-header-container">
        <div class="table-header">
          <div class="table-cell">
            <div class="sixteen-font">Название</div>
          </div>
          <div class="table-cell">
            <div class="sixteen-font">Исполнитель</div>
          </div>
          <div class="table-cell">
            <div class="sixteen-font">Сложность</div>
          </div>
        </div>
      </div>
      <div class="table">
        <div
            v-for="song in songs"
            :key="song.name"
            class="table-row"
            @click="goToSongPage(song.name, song.author)"
        >
          <div class="table-cell">
            <div class="eighteen-font-400w">{{ song.name }}</div>
          </div>
          <div class="table-cell">
            <div class="eighteen-font-400w">{{ song.author }}</div>
          </div>
          <div class="table-cell">
            <StarRating :rating="getStarCount(song.difficultInStars)" />
          </div>
        </div>
      </div>
    </div>

    <div class="pagination-container">
      <div class="pagination">
        <button @click="prevPage" :disabled="currentPage === 0" class="page-btn">&lt;</button>
        <div class="pages">
          <button
              v-for="page in totalPages"
              :key="page"
              @click="setPage(page - 1)"
              :class="{ active: currentPage === page - 1 }"
              class="page-number"
          >
            {{ page }}
          </button>
        </div>
        <button @click="nextPage" :disabled="currentPage + 1 >= totalPages" class="page-btn">&gt;</button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, watch, computed } from 'vue';
import genreApi from '@/api/GenreApi';
import songApi from '@/api/SongApi';
import Dropdown from "@/components/common/Dropdown.vue";
import StarRating from "@/components/common/StarRating.vue";

export default defineComponent({
  components: { Dropdown, StarRating },
  setup() {
    const genres = ref<string[]>([]);
    const languages = ref<string[]>([]);
    const difficulties = ref<BackendT['DifficultInStarsDto'][]>([]);
    const songs = ref<BackendT['SongSearchDataResponse'][]>([]);
    const searchQuery = ref('');
    const selectedGenre = ref('');
    const selectedLanguage = ref('');
    const selectedDifficulty = ref('');
    const currentPage = ref(0);
    const totalPages = ref(1);

    // Функция для преобразования строки звездочек в число
    const getStarCount = (stars: string): number => {
      return stars.length; // Количество звездочек в строке
    };

    const difficultyOptions = computed(() => {
      return difficulties.value.map(d => ({
        label: d.stars, // Отображаем строку звездочек
        value: d.name,  // Передаем имя сложности на бэкенд
        stars: d.stars, // Сохраняем строку звездочек для отображения
      }));
    });

    const fetchFilters = async () => {
      [genres.value, languages.value, difficulties.value] = await Promise.all([
        genreApi.fetchAllSubgenreNames(),
        songApi.fetchSongLanguages(),
        songApi.fetchDifficultInStars(),
      ]);
    };

    const fetchSongs = async () => {
      const response = await songApi.fetchSongsSearchData(
          selectedGenre.value,
          selectedLanguage.value,
          selectedDifficulty.value, // Передаем имя сложности
          searchQuery.value,
          currentPage.value,
          13
      );
      songs.value = response.content || [];
      totalPages.value = response.totalPages || 1;
    };

    const prevPage = () => {
      if (currentPage.value > 0) {
        currentPage.value--;
        fetchSongs();
      }
    };

    const nextPage = () => {
      if (currentPage.value + 1 < totalPages.value) {
        currentPage.value++;
        fetchSongs();
      }
    };

    const setPage = (page: number) => {
      currentPage.value = page;
      fetchSongs();
    };

    const goToSongPage = (songName: string, author: string) => {
      console.log('Переход на страницу песни:', songName, author);
    };

    watch([selectedGenre, selectedLanguage, selectedDifficulty, searchQuery], () => {
      currentPage.value = 0;
      fetchSongs();
    });

    onMounted(() => {
      fetchFilters();
      fetchSongs();
    });

    return {
      genres,
      languages,
      difficulties,
      songs,
      searchQuery,
      selectedGenre,
      selectedLanguage,
      selectedDifficulty,
      currentPage,
      totalPages,
      difficultyOptions,
      getStarCount,
      prevPage,
      nextPage,
      setPage,
      goToSongPage,
    };
  },
});
</script>

<style scoped>
.search-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: white;
}

.filters {
  display: flex;
  gap: 12px;
}

.search-input {
  background: black;
  color: white;
  border: 1px solid #ff8800;
  padding: 8px;
  width: 500px;
}

.table-container {
  width: 100%;
  margin-top: 20px;
}

.table-header-container,
.table {
  width: 100%;
}

.table-header {
  display: flex;
  width: 100%;
  border: none;
}

.table {
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-collapse: collapse;
}

.table-row {
  display: flex;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  transition: background-color 0.3s, transform 0.3s;
  cursor: pointer;
}

.table-cell {
  padding: 20px;
  text-align: left;
  border: none;
  transition: transform 0.3s, color 0.3s;
}
.table-cell .sixteen-font {
  color: #676767;
}

.table-header > .table-cell {
  border-left: none;
  border-right: none;
}

.table-cell:nth-child(1),
.table-cell:nth-child(2) {
  width: 20%;
}

.table-cell:nth-child(3) {
  text-align: right;
  width: 60%;
}

.table-row:hover {
  background-color: rgba(255, 126, 7, 1);
}

.table-row:hover .table-cell {
  transform: translateX(10px);
  color: black;
}

.pagination-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination {
  display: flex;
  gap: 8px;
}

.page-btn,
.page-number {
  background: black;
  color: white;
  border: 1px solid #ff8800;
  padding: 6px 12px;
  cursor: pointer;
}

.page-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.page-number.active {
  background-color: rgba(255, 126, 7, 1);
  color: black;
}
</style>