import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "new-songs" }
const _hoisted_2 = { class: "NewSongsPosts" }
const _hoisted_3 = { class: "table" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "table-cell" }
const _hoisted_6 = { class: "eighteen-font-400w" }
const _hoisted_7 = { class: "table-cell" }
const _hoisted_8 = { class: "eighteen-font-400w" }
const _hoisted_9 = { class: "table-cell" }
const _hoisted_10 = { class: "difficulty" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SongUserCollectionElement = _resolveComponent("SongUserCollectionElement")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createStaticVNode("<div class=\"table-header-container\" data-v-6e205452><div class=\"table-header\" data-v-6e205452><div class=\"table-cell\" data-v-6e205452><div class=\"sixteen-font\" data-v-6e205452>Название</div></div><div class=\"table-cell\" data-v-6e205452><div class=\"sixteen-font\" data-v-6e205452>Исполнитель</div></div><div class=\"table-cell\" data-v-6e205452><div class=\"sixteen-font\" data-v-6e205452>Сложность</div></div></div></div>", 1)),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.newSongs, (song, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "table-row",
            onClick: ($event: any) => (_ctx.goToSongPage(song.songName, song.authorPseudonym))
          }, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, _toDisplayString(song.songName), 1)
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, _toDisplayString(song.authorPseudonym), 1)
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                (_openBlock(), _createElementBlock(_Fragment, null, _renderList(5, (star) => {
                  return _createElementVNode("span", {
                    key: star,
                    class: _normalizeClass({
                  star: true,
                  'orange-star': star <= song.difficultInStars.length,
                  'gray-star': star > song.difficultInStars.length,
                })
                  }, null, 2)
                }), 64)),
                _createVNode(_component_SongUserCollectionElement, {
                  songId: song.id,
                  userCollections: _ctx.userCollections,
                  songCollections: song.inCollections,
                  onToggleCollection: _ctx.toggleCollection
                }, null, 8, ["songId", "userCollections", "songCollections", "onToggleCollection"])
              ])
            ])
          ], 8, _hoisted_4))
        }), 128))
      ])
    ])
  ]))
}