<template>
  <div class="profile-container">
    <div class="main-container">
      <div class="left-column">
        <div class="profile-photo">
          <img :src="userInfo.photo || '/default-avatar.png'" alt="User Photo"/>
          <button @click="editProfile" class="edit-button eighteen-font-400w">Редактировать</button>
        </div>
      </div>

      <div class="right-column">
        <div class="profile-info">
          <div class="profile-row eighteen-font-400w"><span class="label">Полное имя:</span>
            <span>{{ userInfo.fullName || 'Не указано' }}</span></div>
          <div class="profile-row eighteen-font-400w"><span class="label">Имя пользователя:</span>
            <span>{{ userInfo.username || 'Не указано' }}</span></div>
          <div class="profile-row eighteen-font-400w"><span class="label">Номер телефона:</span>
            <span>{{ userInfo.phone || 'Не указан' }}</span></div>
          <div class="profile-row eighteen-font-400w"><span class="label">Статус подписки:</span> <span
              class="subscription-status">{{ userInfo.subscriptionStatus ? 'Активна' : 'Неактивна' }}</span></div>
          <div class="profile-row eighteen-font-400w"><span class="label">Связанные аккаунты:</span> <span
              class="linked-accounts">Яндекс.Музыка, Spotify</span></div>
        </div>

        <div class="bottom-section">
          <div class="favorite-genre">
            <div class="eighteen-font-400w">Любимый жанр</div>
            <span class="genre-label">{{ userInfo.favoriteGenre || 'Не указан' }}</span>
          </div>

          <div class="last-viewed-songs">
            <div class="eighteen-font-400w">Последние просмотренные песни</div>
            <div class="song-covers">
              <div v-for="(cover, index) in userInfo.lastViewedSongCovers" :key="index" class="song-cover">
                <img :src="cover || '/default-cover.png'" alt="Song Cover"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="country-overlay">{{ userInfo.country || '' }}</div>
  </div>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref} from 'vue';
import userApi from "@/api/UserApi";

export default defineComponent({
  setup() {
    const userInfo = ref<UserT['UserInfoResponse']>({
      fullName: '',
      username: '',
      subscriptionStatus: false,
      email: null,
      phone: '',
      favoriteGenre: '',
      lastViewedSongCovers: [],
      photo: null,
      country: ''
    });

    const fetchUserInfo = async () => {
      try {
        userInfo.value = await userApi.getUserInfo();
      } catch (error) {
        console.error('Ошибка загрузки данных пользователя:', error);
      }
    };

    const editProfile = () => {
      console.log('Редактировать профиль');
    };

    onMounted(() => {
      fetchUserInfo();
    });

    return {
      userInfo,
      editProfile,
    };
  },
});
</script>

<style scoped>
.profile-container {
  color: white;
  padding: 2%;
  font-family: Arial, sans-serif;
  position: relative;
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
}

.main-container {
  display: flex;
  gap: 0;
  margin-bottom: 4%;
  border: 1px solid rgba(255, 255, 255, 0.1);
  height: 50vh;
  box-sizing: border-box;
}

.left-column {
  width: 25%;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 2%;
  box-sizing: border-box;
}

.profile-photo {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.profile-photo img {
  border-radius: 50%;
  width: 100%;
  max-width: 20vw;
  height: auto;
  aspect-ratio: 1 / 1;
  object-fit: cover;
}

.edit-button {
  padding: 1% 2%;
  background-color: transparent;
  border: 2px solid #FF9900;
  color: #FF9900;
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;
  width: 100%;
  max-width: 15vw;
  margin-top: 2%;
  box-sizing: border-box;
}

.edit-button:hover {
  background-color: #FF9900;
  color: white;
}

.right-column {
  width: 75%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.profile-info {
  height: 20vh;
  padding: 2%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  overflow: auto;
}

.profile-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.3%;
}

.label {
  color: #aaa;
}

.subscription-status {
  color: #FF9900;
  font-weight: bold;
}

.linked-accounts {
  color: #1E90FF;
  cursor: pointer;
}

.bottom-section {
  flex: 1;
  display: flex;
  box-sizing: border-box;
}

.favorite-genre {
  width: 20vw;
  padding: 2%;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  overflow: auto;
  position: relative;
}

.favorite-genre h2 {
  margin: 0;
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: clamp(16px, 2vw, 18px);
}

.genre-label {
  font-size: clamp(24px, 5vw, 48px);
  font-weight: bold;
  color: #FF9900;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.last-viewed-songs {
  flex: 1;
  padding: 2%;
  box-sizing: border-box;
  overflow: auto;
  position: relative;
}

.last-viewed-songs h2 {
  margin: 0;
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: clamp(16px, 2vw, 18px);
}

.song-covers {
  display: flex;
  gap: 1%;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
}

.song-cover img {
  width: 8vw;
  height: 8vw;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #fff;
  box-sizing: border-box;
}

.country-overlay {
  position: absolute;
  top: 30%;
  right: 30%;
  z-index: -1;
  font-size: clamp(80px, 15vw, 160px);
  font-weight: bold;
  color: rgba(255, 255, 255, 0.05);
}
</style>