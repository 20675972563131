import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "search-container" }
const _hoisted_2 = { class: "filters" }
const _hoisted_3 = { class: "search-box" }
const _hoisted_4 = { class: "table-container" }
const _hoisted_5 = { class: "table" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "table-cell" }
const _hoisted_8 = { class: "eighteen-font-400w" }
const _hoisted_9 = { class: "table-cell" }
const _hoisted_10 = { class: "eighteen-font-400w" }
const _hoisted_11 = { class: "table-cell" }
const _hoisted_12 = { class: "pagination-container" }
const _hoisted_13 = { class: "pagination" }
const _hoisted_14 = ["disabled"]
const _hoisted_15 = { class: "pages" }
const _hoisted_16 = ["onClick"]
const _hoisted_17 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_StarRating = _resolveComponent("StarRating")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchQuery) = $event)),
          type: "text",
          placeholder: "Поиск",
          class: "search-input"
        }, null, 512), [
          [_vModelText, _ctx.searchQuery]
        ])
      ]),
      _createVNode(_component_Dropdown, {
        modelValue: _ctx.selectedGenre,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedGenre) = $event)),
        options: _ctx.genres.map(g => ({ label: g, value: g })),
        placeholder: "Жанр"
      }, null, 8, ["modelValue", "options"]),
      _createVNode(_component_Dropdown, {
        modelValue: _ctx.selectedLanguage,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedLanguage) = $event)),
        options: _ctx.languages.map(l => ({ label: l, value: l })),
        placeholder: "Язык"
      }, null, 8, ["modelValue", "options"]),
      _createVNode(_component_Dropdown, {
        modelValue: _ctx.selectedDifficulty,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedDifficulty) = $event)),
        options: _ctx.difficultyOptions,
        placeholder: "Сложность"
      }, {
        option: _withCtx(({ option }) => [
          _createVNode(_component_StarRating, {
            rating: _ctx.getStarCount(option.stars)
          }, null, 8, ["rating"])
        ]),
        _: 1
      }, 8, ["modelValue", "options"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _cache[6] || (_cache[6] = _createStaticVNode("<div class=\"table-header-container\" data-v-753f83c5><div class=\"table-header\" data-v-753f83c5><div class=\"table-cell\" data-v-753f83c5><div class=\"sixteen-font\" data-v-753f83c5>Название</div></div><div class=\"table-cell\" data-v-753f83c5><div class=\"sixteen-font\" data-v-753f83c5>Исполнитель</div></div><div class=\"table-cell\" data-v-753f83c5><div class=\"sixteen-font\" data-v-753f83c5>Сложность</div></div></div></div>", 1)),
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.songs, (song) => {
          return (_openBlock(), _createElementBlock("div", {
            key: song.name,
            class: "table-row",
            onClick: ($event: any) => (_ctx.goToSongPage(song.name, song.author))
          }, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, _toDisplayString(song.name), 1)
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, _toDisplayString(song.author), 1)
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createVNode(_component_StarRating, {
                rating: _ctx.getStarCount(song.difficultInStars)
              }, null, 8, ["rating"])
            ])
          ], 8, _hoisted_6))
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_12, [
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("button", {
          onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.prevPage && _ctx.prevPage(...args))),
          disabled: _ctx.currentPage === 0,
          class: "page-btn"
        }, "<", 8, _hoisted_14),
        _createElementVNode("div", _hoisted_15, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.totalPages, (page) => {
            return (_openBlock(), _createElementBlock("button", {
              key: page,
              onClick: ($event: any) => (_ctx.setPage(page - 1)),
              class: _normalizeClass([{ active: _ctx.currentPage === page - 1 }, "page-number"])
            }, _toDisplayString(page), 11, _hoisted_16))
          }), 128))
        ]),
        _createElementVNode("button", {
          onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.nextPage && _ctx.nextPage(...args))),
          disabled: _ctx.currentPage + 1 >= _ctx.totalPages,
          class: "page-btn"
        }, ">", 8, _hoisted_17)
      ])
    ])
  ]))
}