<template>
  <div v-if="isAlbumModalOpen" class="modal-overlay" @click.self="closeAlbumModal">
    <div class="modal-container">
      <div class="modal-title">Создание нового альбома</div>
      <form @submit.prevent="saveAlbum" class="modal-form">
        <input v-model="newAlbum.album.name" placeholder="Название альбома" class="input-field" />
        <input v-model="newAlbum.album.description" placeholder="Описание альбома" class="input-field" />
        <DatePicker v-model="newAlbum.album.release" placeholder="Дата релиза" class="input-field" />
        <ImageUploadComponent
            :title="'Обложка альбома'"
            :description="'Максимальное разрешение: 1500 x 1500. Формат: PNG, JPEG, JPG.'"
            v-model="newAlbum.photo"
        />

        <button type="submit" class="save-button">Сохранить</button>
      </form>
    </div>
  </div>
</template>

<script setup lang="ts">
import ImageUploadComponent from "@/components/common/ImageUploadComponent.vue";
import albumApi from "@/api/AlbumApi";
import { AxiosError } from "axios";
import {
  albums,
  albumsSearchQuery,
  errorMessage,
  isAlbumModalOpen,
  newAlbum,
  newSong,
  selectedAlbum,
  showError,
  showSuccess,
  successMessage
} from "@/components/add-song/modal/AddSongFormStates";
import DatePicker from "@vuepic/vue-datepicker";

const saveAlbum = async () => {
  try {
    const response = await albumApi.saveAlbum(newAlbum.value);
    if (response && response.name) {
      albumsSearchQuery.value = response.name;
      selectedAlbum.value = {
        ...newAlbum.value.album,
        id: response.id,
        name: response.name,
      };
      newSong.value.song.albumName = response.name;
      albums.value = [];
    }
    successMessage.value = "Альбом успешно создан";
    showSuccess.value = true;
    closeAlbumModal();
  } catch (error) {
    const axiosError = error as AxiosError<ApiErrorResponse>;
    errorMessage.value = axiosError.response?.data?.message || "Ошибка при создании альбома.";
    showError.value = true;
    console.error("Ошибка при создании альбома:", error);
  }
};

const closeAlbumModal = () => {
  isAlbumModalOpen.value = false;
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-container {
  background-color: #121212;
  padding: 20px;
  border-radius: 10px;
  width: 40%;
  text-align: center;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.modal-title {
  font-size: 1.5rem;
  margin-bottom: 15px;
  color: white;
}

.input-field {
  width: 100%;
  padding: 12px;
  background-color: #2c2c2c;
  border: 1px solid #444;
  border-radius: 4px;
  color: white;
  font-size: 16px;
  margin: 10px 0;
}

.save-button {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.save-button {
  background-color: #ff6600;
  color: white;
}

.save-button:hover {
  background-color: #e65c00;
}
</style>
