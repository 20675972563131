import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "split-container"
}
const _hoisted_2 = { class: "image-container" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "SongName" }
const _hoisted_5 = { class: "eighteen-font-400w" }
const _hoisted_6 = { class: "Author" }
const _hoisted_7 = { class: "eighteen-font-400w" }
const _hoisted_8 = { class: "Genre" }
const _hoisted_9 = { class: "eighteen-font-400w" }
const _hoisted_10 = { class: "other-posts" }
const _hoisted_11 = ["onClick"]
const _hoisted_12 = { class: "SharpNumber" }
const _hoisted_13 = { class: "SongName" }
const _hoisted_14 = { class: "eighteen-font-400w" }
const _hoisted_15 = { class: "Author" }
const _hoisted_16 = { class: "eighteen-font-400w" }
const _hoisted_17 = { class: "Genre" }
const _hoisted_18 = { class: "eighteen-font-400w" }
const _hoisted_19 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.posts.length > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: "best-post Post",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goToSongPage(_ctx.posts[0].songName, _ctx.posts[0].authorPseudonym)))
        }, [
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "SharpNumber" }, [
            _createElementVNode("i", null, "#1")
          ], -1)),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("img", {
              src: _ctx.posts[0].coverTempUrl,
              alt: 'Изображение песни не загружено!',
              class: "centered-image"
            }, null, 8, _hoisted_3)
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.posts[0].songName), 1)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.posts[0].authorPseudonym), 1)
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.posts[0].subgenre), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_10, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.posts.slice(1), (post, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              style: _normalizeStyle(_ctx.postStyle()),
              class: "post Post",
              onClick: ($event: any) => (_ctx.goToSongPage(post.songName, post.authorPseudonym))
            }, [
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("i", null, "#" + _toDisplayString(index + 2), 1)
              ]),
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("div", _hoisted_14, _toDisplayString(post.songName), 1)
              ]),
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("div", _hoisted_16, _toDisplayString(post.authorPseudonym), 1)
              ]),
              _createElementVNode("div", _hoisted_17, [
                _createElementVNode("div", _hoisted_18, _toDisplayString(post.subgenre), 1)
              ])
            ], 12, _hoisted_11))
          }), 128))
        ])
      ]))
    : (_openBlock(), _createElementBlock("p", _hoisted_19, "Загрузка песен..."))
}