import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "profile-container" }
const _hoisted_2 = { class: "main-container" }
const _hoisted_3 = { class: "left-column" }
const _hoisted_4 = { class: "profile-photo" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "right-column" }
const _hoisted_7 = { class: "profile-info" }
const _hoisted_8 = { class: "profile-row eighteen-font-400w" }
const _hoisted_9 = { class: "profile-row eighteen-font-400w" }
const _hoisted_10 = { class: "profile-row eighteen-font-400w" }
const _hoisted_11 = { class: "profile-row eighteen-font-400w" }
const _hoisted_12 = { class: "subscription-status" }
const _hoisted_13 = { class: "bottom-section" }
const _hoisted_14 = { class: "favorite-genre" }
const _hoisted_15 = { class: "genre-label" }
const _hoisted_16 = { class: "last-viewed-songs" }
const _hoisted_17 = { class: "song-covers" }
const _hoisted_18 = ["src"]
const _hoisted_19 = { class: "country-overlay" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("img", {
            src: _ctx.userInfo.photo || '/default-avatar.png',
            alt: "User Photo"
          }, null, 8, _hoisted_5),
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.editProfile && _ctx.editProfile(...args))),
            class: "edit-button eighteen-font-400w"
          }, "Редактировать")
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _cache[1] || (_cache[1] = _createElementVNode("span", { class: "label" }, "Полное имя:", -1)),
            _createElementVNode("span", null, _toDisplayString(_ctx.userInfo.fullName || 'Не указано'), 1)
          ]),
          _createElementVNode("div", _hoisted_9, [
            _cache[2] || (_cache[2] = _createElementVNode("span", { class: "label" }, "Имя пользователя:", -1)),
            _createElementVNode("span", null, _toDisplayString(_ctx.userInfo.username || 'Не указано'), 1)
          ]),
          _createElementVNode("div", _hoisted_10, [
            _cache[3] || (_cache[3] = _createElementVNode("span", { class: "label" }, "Номер телефона:", -1)),
            _createElementVNode("span", null, _toDisplayString(_ctx.userInfo.phone || 'Не указан'), 1)
          ]),
          _createElementVNode("div", _hoisted_11, [
            _cache[4] || (_cache[4] = _createElementVNode("span", { class: "label" }, "Статус подписки:", -1)),
            _cache[5] || (_cache[5] = _createTextVNode()),
            _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.userInfo.subscriptionStatus ? 'Активна' : 'Неактивна'), 1)
          ]),
          _cache[6] || (_cache[6] = _createElementVNode("div", { class: "profile-row eighteen-font-400w" }, [
            _createElementVNode("span", { class: "label" }, "Связанные аккаунты:"),
            _createTextVNode(),
            _createElementVNode("span", { class: "linked-accounts" }, "Яндекс.Музыка, Spotify")
          ], -1))
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("div", _hoisted_14, [
            _cache[7] || (_cache[7] = _createElementVNode("div", { class: "eighteen-font-400w" }, "Любимый жанр", -1)),
            _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.userInfo.favoriteGenre || 'Не указан'), 1)
          ]),
          _createElementVNode("div", _hoisted_16, [
            _cache[8] || (_cache[8] = _createElementVNode("div", { class: "eighteen-font-400w" }, "Последние просмотренные песни", -1)),
            _createElementVNode("div", _hoisted_17, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.userInfo.lastViewedSongCovers, (cover, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index,
                  class: "song-cover"
                }, [
                  _createElementVNode("img", {
                    src: cover || '/default-cover.png',
                    alt: "Song Cover"
                  }, null, 8, _hoisted_18)
                ]))
              }), 128))
            ])
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.userInfo.country || ''), 1)
  ]))
}