<template>
  <div>Пожалуйста, заполните необходимые поля для создания нового профиля</div>

  <Snackbar color="red" v-model:show="showError" :message="errorMessage"/>
  <Snackbar color="green" v-model:show="showSuccess" :message="successMessage"/>

  <form @submit.prevent="register">
    <div class="form-group">
      <input type="text" id="firstname" v-model="form.firstname" placeholder="Имя"/>
    </div>
    <div class="form-group">
      <input type="text" id="lastname" v-model="form.lastname" placeholder="Фамилия"/>
    </div>
    <div class="form-group">
      <input type="text" id="surname" v-model="form.surname" placeholder="Отчество"/>
    </div>
    <div class="form-group">
      <input type="text" id="username" v-model="form.username" placeholder="Имя пользователя" required/>
    </div>
    <div class="form-group">
      <div class="password-container">
        <input :type="showPassword ? 'text' : 'password'" id="password" v-model="form.password" placeholder="Пароль" required/>
        <div class="eye-container" @click="togglePassword">
          <div :class="['eye', showPassword ? 'eye-closed' : 'eye-open']"></div>
        </div>
      </div>
    </div>
    <div class="form-group">
      <input :type="showPassword ? 'text' : 'password'" id="confirmPassword" v-model="form.confirmPassword" placeholder="Подтвердить пароль" required/>
    </div>
    <div v-if="passwordMismatch" class="error-message">Пароли не совпадают</div>

    <div class="form-group">
      <input type="email" id="email" v-model="form.email" placeholder="Email"/>
    </div>
    <div class="form-group">
      <input type="text" id="phone" v-model="form.phone" placeholder="Телефон"/>
    </div>
    <div class="form-group">
      <label for="photo" class="file-label">Выберите фотографию</label>
      <input type="file" id="photo" @change="handlePhotoChange" accept="image/*" class="file-input"/>
    </div>

    <button type="submit" class="primary-button" :disabled="passwordMismatch">Зарегистрироваться</button>
    <div class="login-link">
      <div class="eighteen-font-400w">Уже зарегистрированы?
        <router-link to="/login" class="login-link-text">Войти</router-link>
      </div>
    </div>
  </form>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue';
import { AxiosError } from "axios";
import router from "@/router/router";
import { CookieManager } from "@/api/base/CookieManager";
import Snackbar from "@/components/common/Snackbar.vue";
import {useAuthStore} from "@/store/authStore";
import authApi from "@/api/AuthApi";

const form = ref({
  firstname: '',
  lastname: '',
  surname: '',
  username: '',
  email: '',
  phone: '',
  password: '',
  confirmPassword: '',
});

const photo = ref<File | null>(null);
const showPassword = ref(false);
const showError = ref(false);
const showSuccess = ref(false);
const errorMessage = ref('');
const successMessage = ref('');
const authStore = useAuthStore();

const handlePhotoChange = (event: Event) => {
  const target = event.target as HTMLInputElement;
  if (target.files) {
    photo.value = target.files[0];
  }
};

const passwordMismatch = computed(() => form.value.password !== form.value.confirmPassword);

const togglePassword = () => {
  showPassword.value = !showPassword.value;
};

const register = async () => {
  if (!photo.value) {
    errorMessage.value = 'Пожалуйста, добавьте фотографию.';
    showError.value = true;
    return;
  }

  if (passwordMismatch.value) return;

  const requestData = {
    photo: photo.value,
    user: {
      firstname: form.value.firstname,
      lastname: form.value.lastname,
      surname: form.value.surname,
      username: form.value.username,
      email: form.value.email,
      phone: form.value.phone,
      password: form.value.password,
      confirmPassword: form.value.confirmPassword,
    },
  };

  try {
    const response = await authApi.signUpUser(requestData);
    if (response.token) {
      CookieManager.setCookie('token', response.token);
      successMessage.value = 'Регистрация прошла успешно';
      showSuccess.value = true;

      // Обновляем состояние аутентификации
      authStore.checkSession();

      setTimeout(() => {
        router.push({ name: 'home' });
      }, 1000);
    }
  } catch (error: unknown) {
    const axiosError = error as AxiosError<ApiErrorResponse>;
    errorMessage.value = axiosError.response?.data?.message || 'Неизвестная ошибка. Попробуйте снова.';
    showError.value = true;
  }
};
</script>

<style scoped>
h2 {
  text-align: center;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

input, select {
  width: 100%;
  padding: 15px;
  background-color: #333;
  color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

input::placeholder {
  color: #ccc;
}

.file-label {
  display: inline-block;
  padding: 10px 15px;
  background-color: #ff7e1b;
  color: white;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
}

.file-input {
  display: none;
}

.primary-button {
  width: 100%;
  padding: 15px;
  background-color: #ff7e1b;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 10px;
}

.primary-button:hover {
  background-color: #e66a00;
}

.error-message {
  color: red;
  font-size: 0.9rem;
  margin-top: -10px;
  margin-bottom: 10px;
}

.login-link {
  text-align: center;
  margin-top: 20px;
}

.login-link-text {
  color: #ff7e1b;
  font-weight: bold;
}

.login-link-text:hover {
  text-decoration: underline;
}

.password-container {
  position: relative;
  display: flex;
  align-items: center;
}

.eye-container {
  width: 30px;
  height: 30px;
  position: absolute;
  right: 10px;
  cursor: pointer;
}

.eye {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #ff7e1b;
}

.eye::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 60%;
  height: 60%;
  background-color: #fff;
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(1);
}

.eye-open::before {
  transform: translate(-50%, -50%) scale(0);
}

.eye-closed::before {
  transform: translate(-50%, -50%) scale(1);
}
</style>