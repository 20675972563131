<template>
  <div
      class="dropdown-container"
      @mouseenter="openDropdown"
      @mouseleave="closeDropdownWithDelay"
  >
    <div class="dropdown-selected" @click="toggleDropdown">
      <span>{{ selectedLabel || placeholder }}</span>
      <svg
          class="dropdown-icon"
          width="12"
          height="8"
          viewBox="0 0 12 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
      >
        <path
            d="M1 1.5L6 6.5L11 1.5"
            stroke="#FF8800"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
      </svg>
    </div>
    <ul v-if="isOpen" class="dropdown-options">
      <li
          v-for="option in options"
          :key="option.value"
          @click="selectOption(option)"
      >
        <!-- Слот для кастомного рендеринга опций -->
        <slot name="option" :option="option">
          {{ option.label }} <!-- По умолчанию отображаем label -->
        </slot>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from "vue";

export default defineComponent({
  props: {
    options: {
      type: Array as () => { label: string; value: string }[],
      required: true,
    },
    modelValue: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "Выберите",
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const isOpen = ref(false);
    let closeTimeout: number | null = null;

    const toggleDropdown = () => {
      isOpen.value = !isOpen.value;
    };

    const openDropdown = () => {
      if (closeTimeout) {
        clearTimeout(closeTimeout);
        closeTimeout = null;
      }
      isOpen.value = true;
    };

    const closeDropdownWithDelay = () => {
      closeTimeout = setTimeout(() => {
        isOpen.value = false;
      }, 150); // Задержка перед закрытием (300 мс)
    };

    const selectOption = (option: { label: string; value: string }) => {
      emit("update:modelValue", option.value);
      isOpen.value = false;
    };

    const selectedLabel = computed(() => {
      const selected = props.options.find(
          (option) => option.value === props.modelValue
      );
      return selected ? selected.label : "";
    });

    return {
      isOpen,
      toggleDropdown,
      openDropdown,
      closeDropdownWithDelay,
      selectOption,
      selectedLabel,
    };
  },
});
</script>

<style scoped>
.dropdown-container {
  background: #181818;
  color: white;
  border-radius: 8px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  min-width: 200px;
}

.dropdown-selected {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-icon {
  transition: transform 0.3s;
}

.dropdown-options {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: #202020;
  border-radius: 8px;
  padding: 8px 0;
  margin-top: 4px;
  list-style: none;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
}

.dropdown-options li {
  padding: 10px;
  transition: background 0.3s;
  cursor: pointer;
}

.dropdown-options li:hover {
  background: #ff8800;
  color: black;
}
</style>