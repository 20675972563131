import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "song-view" }
const _hoisted_3 = { class: "song-content" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SongWithChordsDisplay = _resolveComponent("SongWithChordsDisplay")!

  return (_ctx.song)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h1", null, _toDisplayString(_ctx.song.authorPseudonym + " - " + _ctx.song.name), 1),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_SongWithChordsDisplay, {
              songData: _ctx.song.text
            }, null, 8, ["songData"])
          ]),
          _cache[0] || (_cache[0] = _createElementVNode("div", { class: "chords-images" }, null, -1))
        ])
      ]))
    : (_ctx.loading)
      ? (_openBlock(), _createElementBlock("p", _hoisted_4, "Загрузка данных песни..."))
      : (_ctx.error)
        ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.error), 1))
        : _createCommentVNode("", true)
}