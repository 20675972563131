<template>
  <div class="image-upload-card">
    <div class="content-container">
      <div class="left-section">
        <div class="upload-area" @click="triggerFileInput">
          <input
              type="file"
              accept="image/png, image/jpeg"
              @change="onImageChange"
              ref="fileInput"
              hidden
          />
          <div v-if="!imageFile" class="plus-sign">+</div>
          <div v-if="imageFile" class="image-container">
            <img :src="imageFileUrl" class="image-preview" :style="imageStyle" ref="imageElement"/>
          </div>
        </div>
      </div>

      <div class="right-section">
        <div class="title">Обложка песни</div>
        <p class="description">*Добавьте или перетащите<br>*Максимальное разрешение: 1500 x 1500<br>*Формат: PNG, JPEG, JPG</p>

        <div class="controls">
          <button @click="removeImage" class="icon-button"><i class="trash-icon"></i></button>
          <button @click="rotateLeft" class="icon-button"><i class="rotate-left-icon"></i></button>
          <button @click="rotateRight" class="icon-button"><i class="rotate-right-icon"></i></button>
        </div>

        <div class="slider-container">
          <input type="range" v-model="imageScale" min="1" max="2" step="0.05" @input="resizeImage"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, defineProps, defineEmits, watch, nextTick } from 'vue';

const props = defineProps({
  modelValue: File,
  title: String,
  description: String
});
const emit = defineEmits(["update:modelValue"]);

const fileInput = ref<HTMLInputElement | null>(null);
const imageFile = ref<File | null>(props.modelValue || null);
const imageScale = ref(1);
const imageRotation = ref(0);
const imageElement = ref<HTMLImageElement | null>(null);

const placeholderImage = '/logo.ico';
const imageFileUrl = ref<string>(placeholderImage);

watch(imageFile, (newFile, oldFile) => {
  if (oldFile && imageFileUrl.value.startsWith('blob:')) {
    URL.revokeObjectURL(imageFileUrl.value);
  }
  imageFileUrl.value = newFile ? URL.createObjectURL(newFile) : placeholderImage;
});

const triggerFileInput = () => {
  fileInput.value?.click();
};

const onImageChange = (event: Event) => {
  const input = event.target as HTMLInputElement;
  const file = input.files?.[0];
  if (file) {
    imageFile.value = file;
    emit("update:modelValue", file);
    emitModifiedImage();
  }
};

const removeImage = () => {
  imageFile.value = null;
  imageScale.value = 1;
  imageRotation.value = 0;
  emit("update:modelValue", null);
};

const rotateLeft = () => {
  imageRotation.value -= 90;
  emitModifiedImage();
};

const rotateRight = () => {
  imageRotation.value += 90;
  emitModifiedImage();
};

const resizeImage = () => {
  emitModifiedImage();
};

const emitModifiedImage = () => {
  if (!imageFile.value) {
    emit("update:modelValue", null);
    return;
  }

  const img = new Image();
  const reader = new FileReader();
  reader.readAsDataURL(imageFile.value);

  reader.onload = () => {
    img.src = reader.result as string;
    img.onload = () => {
      const containerSize = 200;
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      if (!ctx) return;

      canvas.width = containerSize;
      canvas.height = containerSize;
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      const aspectRatio = img.naturalWidth / img.naturalHeight;
      let drawWidth, drawHeight;

      if (aspectRatio > 1) {
        drawWidth = containerSize * imageScale.value;
        drawHeight = drawWidth / aspectRatio;
      } else {
        drawHeight = containerSize * imageScale.value;
        drawWidth = drawHeight * aspectRatio;
      }

      ctx.translate(containerSize / 2, containerSize / 2);
      ctx.rotate((imageRotation.value * Math.PI) / 180);
      ctx.drawImage(img, -drawWidth / 2, -drawHeight / 2, drawWidth, drawHeight);

      canvas.toBlob((blob) => {
        if (blob) {
          const modifiedFile = new File([blob], imageFile.value!.name, {
            type: imageFile.value!.type,
          });
          emit("update:modelValue", modifiedFile);
        }
      }, imageFile.value!.type);
    };
  };

  img.onerror = () => {
    console.error("Ошибка загрузки изображения!");
  };
};

const imageStyle = computed(() => {
  return {
    transform: `scale(${imageScale.value}) rotate(${imageRotation.value}deg)`,
  };
});

watch([imageFile, imageScale, imageRotation], async () => {
  await nextTick();
  emitModifiedImage();
}, { flush: 'post' });

</script>

<style scoped>
.image-upload-card {
  background: transparent;
  padding: 20px;
  border-radius: 12px;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
}

.content-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  align-items: center;
}

.left-section, .right-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  color: white;
  font-size: 18px;
  margin: 0 0 10px;
}

.description {
  color: #888;
  font-size: 12px;
  margin-bottom: 10px;
  line-height: 1.4;
}

.upload-area {
  width: 200px; /* Фиксированная ширина */
  height: 200px; /* Фиксированная высота */
  background: #1e1e1e;
  border: 2px dashed #ff8c00;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s;
  overflow: hidden;
  position: relative;
}

.image-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.image-preview {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform-origin: center;
  transition: transform 0.2s;
}


.controls {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.icon-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: #ff8c00;
}

.slider-container {
  margin-top: 10px;
  width: 100%;
}

input[type="range"] {
  width: 100%;
  accent-color: #ff8c00;
}

@media (max-width: 768px) {
  .content-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
</style>