import { defineStore } from 'pinia';
import userApi from '@/api/UserApi';
import { CookieManager } from '@/api/base/CookieManager';

export const useAuthStore = defineStore('auth', {
    state: () => ({
        isSessionActive: false,
        userInfo: {
            username: '',
            photo: null as string | null,
        },
        sessionChecked: false,
    }),
    actions: {
        async checkSession() {
            const token = CookieManager.getCookie('token');
            if (!token) {
                this.sessionChecked = true;
                return;
            }

            try {
                const sessionData = await userApi.getUserInfo();

                if (sessionData) {
                    this.userInfo = sessionData;
                    this.isSessionActive = true;
                }
            } catch (error) {
                console.error('Ошибка получения сессии пользователя:', error);
                this.isSessionActive = false;
            } finally {
                this.sessionChecked = true;
            }
        },
        logout() {
            CookieManager.deleteCookie('token');
            this.isSessionActive = false;
            this.userInfo = { username: '', photo: null };
            this.sessionChecked = true;
        },
    },
});