import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/icons/ico/logo.ico'


const _hoisted_1 = { class: "TopMenuWrapper" }
const _hoisted_2 = { class: "BurgerMenu-item" }
const _hoisted_3 = { class: "BurgerMenu-item" }
const _hoisted_4 = { class: "BurgerMenu-item" }
const _hoisted_5 = { class: "BurgerMenu-item" }
const _hoisted_6 = { class: "MainLogoWrapper" }
const _hoisted_7 = { class: "PageButtonsWrapper" }
const _hoisted_8 = { class: "ExtraButtonsWrapper" }
const _hoisted_9 = ["src"]
const _hoisted_10 = {
  key: 0,
  class: "username-hover"
}
const _hoisted_11 = { class: "Content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: "BurgerWrapper",
        onMouseenter: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showBurgerMenu && _ctx.showBurgerMenu(...args))),
        onMouseleave: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.hideBurgerMenu && _ctx.hideBurgerMenu(...args)))
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["BurgerButton", { 'active': _ctx.burgerMenuActive }])
        }, null, 2),
        _createElementVNode("div", {
          class: _normalizeClass(["BurgerMenu", { 'active': _ctx.burgerMenuActive }]),
          ref: "burgerMenu"
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_router_link, {
              to: "/chordLibrary",
              class: "BurgerMenu-link"
            }, {
              default: _withCtx(() => _cache[7] || (_cache[7] = [
                _createTextVNode("Библиотека аккордов")
              ])),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_router_link, {
              to: "/tuner",
              class: "BurgerMenu-link"
            }, {
              default: _withCtx(() => _cache[8] || (_cache[8] = [
                _createTextVNode("Тюнер")
              ])),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_router_link, {
              to: "/addSong",
              class: "BurgerMenu-link"
            }, {
              default: _withCtx(() => _cache[9] || (_cache[9] = [
                _createTextVNode("Добавить песню")
              ])),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_router_link, {
              to: "/upload-chords",
              class: "BurgerMenu-link"
            }, {
              default: _withCtx(() => _cache[10] || (_cache[10] = [
                _createTextVNode("Добавить аккорды")
              ])),
              _: 1
            })
          ])
        ], 2)
      ], 32),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_router_link, {
          to: "/",
          class: "LogoLink"
        }, {
          default: _withCtx(() => _cache[11] || (_cache[11] = [
            _createElementVNode("img", {
              src: _imports_0,
              alt: "Логотип сайта",
              class: "Logo"
            }, null, -1)
          ])),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_router_link, {
          to: "/songsLibrary",
          class: "Navbar-link"
        }, {
          default: _withCtx(() => _cache[12] || (_cache[12] = [
            _createElementVNode("button", { class: "Navbar-button" }, [
              _createElementVNode("div", { class: "eighteen-font-500w" }, "Песни")
            ], -1)
          ])),
          _: 1
        }),
        _createVNode(_component_router_link, {
          to: "/allGenres",
          class: "Navbar-link"
        }, {
          default: _withCtx(() => _cache[13] || (_cache[13] = [
            _createElementVNode("button", { class: "Navbar-button" }, [
              _createElementVNode("div", { class: "eighteen-font-500w" }, "Жанры")
            ], -1)
          ])),
          _: 1
        }),
        _createVNode(_component_router_link, {
          to: "/allMusicians",
          class: "Navbar-link"
        }, {
          default: _withCtx(() => _cache[14] || (_cache[14] = [
            _createElementVNode("button", { class: "Navbar-button" }, [
              _createElementVNode("div", { class: "eighteen-font-500w" }, "Исполнители")
            ], -1)
          ])),
          _: 1
        })
      ]),
      _cache[17] || (_cache[17] = _createElementVNode("div", { class: "SearchWrapper" }, [
        _createElementVNode("input", {
          type: "text",
          class: "SearchInput",
          placeholder: "Поиск..."
        }),
        _createElementVNode("span", { class: "SearchIcon" }, "🔍")
      ], -1)),
      _createElementVNode("div", _hoisted_8, [
        _cache[15] || (_cache[15] = _createElementVNode("button", { class: "LangButton" }, "En", -1)),
        _cache[16] || (_cache[16] = _createElementVNode("button", { class: "ThemeButton" }, "🌞", -1)),
        _createElementVNode("div", {
          class: _normalizeClass({'ProfileWrapper': true, 'visible': _ctx.authStore.sessionChecked && _ctx.authStore.isSessionActive})
        }, [
          _createElementVNode("div", {
            class: "profile-container",
            onMouseenter: _cache[4] || (_cache[4] = ($event: any) => (_ctx.toggleUserInfo(true))),
            onMouseleave: _cache[5] || (_cache[5] = ($event: any) => (_ctx.toggleUserInfo(false)))
          }, [
            _createElementVNode("img", {
              src: _ctx.authStore.userInfo.photo || '/default-avatar.png',
              alt: "User Photo",
              class: "profile-circle"
            }, null, 8, _hoisted_9),
            (_ctx.showUserInfo)
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                  _createElementVNode("div", null, _toDisplayString(_ctx.authStore.userInfo.username), 1),
                  _createElementVNode("button", {
                    onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.goToProfile && _ctx.goToProfile(...args)))
                  }, "Перейти в профиль"),
                  _createElementVNode("button", {
                    onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.logout && _ctx.logout(...args)))
                  }, "Выйти")
                ]))
              : _createCommentVNode("", true)
          ], 32)
        ], 2),
        _createElementVNode("button", {
          class: _normalizeClass({'JoinButton': true, 'visible': _ctx.authStore.sessionChecked && !_ctx.authStore.isSessionActive}),
          onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.goToLogin && _ctx.goToLogin(...args)))
        }, " Присоединиться ", 2)
      ])
    ]),
    _createElementVNode("div", _hoisted_11, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ], 64))
}