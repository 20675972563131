import {BaseApi} from "@/api/base/BaseApi";

const API_PREFIX = '/backend/collection';

export class CollectionApi {
    private client: BaseApi;

    constructor() {
        this.client = new BaseApi();
    }

    public async getUserCollections(): Promise<BackendT["GetUserCollectionResponse"][]> {
        try {
            return this.client.get<BackendT["GetUserCollectionResponse"][]>(API_PREFIX + `/all`, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
        } catch (error) {
            throw new Error(`Не удалось получить коллекции пользователя: ${error}`);
        }
    }

    public async addSongToCollection(collectionId: string, songId: string): Promise<BackendT["SongAddedOrNotToCollection"]> {
        try {
            return await this.client.post<BackendT["SongAddedOrNotToCollection"]>(API_PREFIX + `/add-or-remove/${songId}/in/${collectionId}`, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
        } catch (error) {
            throw new Error(`Не удалось добавить песню в пользовательскую коллекцию: ${error}`);
        }
    }

    public async createCollection(cover: File, userCollectionTitle: string)
        : Promise<BackendT["CreateUserCollectionResponse"]> {
        try {
            const createUserCollectionRequest: BackendT["CreateUserCollectionRequest"] = {
                title: userCollectionTitle
            };

            const formData = new FormData();
            formData.append('cover', cover, 'userCollectionCover.jpg');
            formData.append('userCollection', new Blob([JSON.stringify(createUserCollectionRequest)],
                {type: 'application/json'}));

            return await this.client.post<BackendT["CreateUserCollectionResponse"]>(API_PREFIX, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });
        } catch (error) {
            throw new Error(`Не удалось создать пользовательскую коллекцию: ${error}`);
        }
    }
}

export default new CollectionApi();
