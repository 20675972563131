<template>
  <div id="FretFlex" class="fretflex">
    <MainLayout>
      <router-view></router-view>
    </MainLayout>
  </div>
</template>

<script>
import MainLayout from '@/layouts/MainLayout.vue';
import '@/assets/global-styles.css'

export default {
  name: "FretFlex",
  components: {
    MainLayout
  }
};
</script>

<style scoped>
</style>