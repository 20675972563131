<template>
  <div
      v-if="internalShow"
      class="snackbar"
      :style="{ backgroundColor: color }"
  >
    <div class="message">{{ message }}</div>
    <button
        class="close-button"
        @click="close"
    >
      Закрыть
    </button>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';

const props = defineProps({
  color: {
    type: String,
    required: true
  },
  show: {
    type: Boolean,
    required: true
  },
  message: {
    type: String,
    required: true
  }
});

const emit = defineEmits(['update:show']);

const internalShow = ref(props.show);
let timeoutId: number;

watch(() => props.show, (newVal) => {
  internalShow.value = newVal;
  if (newVal) {
    timeoutId = setTimeout(() => {
      close();
    }, 6000);
  }
});

watch(internalShow, (newVal) => {
  emit('update:show', newVal);
});

const close = () => {
  internalShow.value = false;
  clearTimeout(timeoutId);
};
</script>

<style scoped>
.snackbar {
  position: fixed;
  top: 20px;
  right: 20px;
  min-width: 300px;
  max-width: 600px;
  padding: 16px 24px;
  border-radius: 4px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  animation: slideIn 0.3s ease-out;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.message {
  flex-grow: 1;
  word-break: break-word;
}

.close-button {
  background-color: #ff6600;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  flex-shrink: 0;
  transition: opacity 0.2s;
}

.close-button:hover {
  opacity: 0.9;
}
</style>