<template>
  <div v-if="posts.length > 0" class="split-container">

    <div class="best-post Post" @click="goToSongPage(posts[0].songName, posts[0].authorPseudonym)">
      <div class="SharpNumber"><i>#1</i></div>
      <div class="image-container">
        <img
            :src="posts[0].coverTempUrl"
            :alt="'Изображение песни не загружено!'"
            class="centered-image"
        />
      </div>
      <div class="SongName">
        <div class="eighteen-font-400w">{{ posts[0].songName }}</div>
      </div>
      <div class="Author">
        <div class="eighteen-font-400w">{{ posts[0].authorPseudonym }}</div>
      </div>
      <div class="Genre">
        <div class="eighteen-font-400w">{{ posts[0].subgenre }}</div>
      </div>
    </div>

    <div class="other-posts">
      <div
          v-for="(post, index) in posts.slice(1)"
          :key="index"
          :style="postStyle()"
          class="post Post"
          @click="goToSongPage(post.songName, post.authorPseudonym)"
      >
        <div class="SharpNumber"><i>#{{ index + 2 }}</i></div>
        <div class="SongName">
          <div class="eighteen-font-400w">{{ post.songName }}</div>
        </div>
        <div class="Author">
          <div class="eighteen-font-400w">{{ post.authorPseudonym }}</div>
        </div>
        <div class="Genre">
          <div class="eighteen-font-400w">{{ post.subgenre }}</div>
        </div>
      </div>
    </div>

  </div>
  <p v-else>Загрузка песен...</p>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';
import songApi from '@/api/SongApi';
import { useRouter } from 'vue-router';

export default defineComponent({
  setup() {
    const posts = ref<BackendT['SongPreviewResponse'][]>([]);
    const router = useRouter();

    const fetchTopSongs = async () => {
      try {
        const response = await songApi.fetchTopSongsByRating(5, 0, 1000, 'DESC');
        if (Array.isArray(response)) {
          posts.value = response;
        } else {
          console.error('Неверный формат ответа:', response);
        }
      } catch (error) {
        console.error('Ошибка загрузки песен:', error);
      }
    };

    const goToSongPage = (songName: string | undefined, authorPseudonym: string | null) => {
      if (!songName || !authorPseudonym) {
        console.error('Invalid song data: songName or authorPseudonym is missing.');
        return;
      }

      router.push({
        name: 'songView',
        params: { songName, authorPseudonym },
      });
    };


    const postStyle = () => ({
      height: `calc(100% / ${Math.max(posts.value.length - 1, 1)})`,
    });

    onMounted(() => {
      fetchTopSongs();
    });

    return {
      posts,
      postStyle,
      goToSongPage, // Return goToSongPage to use in the template
    };
  },
});
</script>


<style scoped>
.split-container {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.best-post {
  width: 50%;
  padding: 5px;
  box-sizing: border-box;
  position: relative;
  aspect-ratio: 1;
}

.Post {
  cursor: pointer;
  border: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  transition: background-color 0.3s;
}

.Post:hover {
  background-color: rgba(255, 126, 7, 1);
}

.image-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 100%;
  overflow: hidden;
}

.best-post:hover .SongName,
.best-post:hover .Genre,
.best-post:hover .Author,
.best-post:hover .SharpNumber {
  color: rgba(6, 6, 6, 1);
}

.other-posts .post:hover .SongName,
.other-posts .post:hover .Genre,
.other-posts .post:hover .Author,
.other-posts .post:hover .SharpNumber {
  color: rgba(6, 6, 6, 1);
}

.best-post:hover .image-container img {
  transform: translate(-50%, -50%) scale(1.7);
  border-radius: 80%;
}

.best-post .image-container img {
  transform: translate(-50%, -50%) scale(1);
  border-radius: 5%;
}

.Post img {
  width: 50%;
  height: 50%;
  object-fit: cover;
}

.centered-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.Author,
.SongName,
.Genre,
.SharpNumber {
  position: absolute;
  padding: 5px;
}

.Author {
  bottom: 5px;
  left: 5px;
  color: rgba(136, 136, 136, 1);
}

.SongName {
  bottom: 25px;
  left: 5px;
  color: rgba(255, 255, 255, 1);
}

.Genre {
  top: 5px;
  left: 5px;
  color: rgba(39, 39, 39, 1);
}

.SharpNumber {
  top: 5px;
  right: 5px;
  font-size: 17px;
  color: rgba(255, 126, 7, 1);
}

.other-posts {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.other-posts .post {
  padding: 5px;
}

@media (max-width: 768px) {
  .best-post,
  .other-posts .post {
    width: 100%;
  }

  .split-container {
    flex-direction: column;
  }
}
</style>
