import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "popular-authors" }
const _hoisted_2 = { class: "bestAuthorsPosts" }
const _hoisted_3 = {
  key: 0,
  class: "author-container"
}
const _hoisted_4 = ["onMouseover", "onClick"]
const _hoisted_5 = { class: "author-square" }
const _hoisted_6 = { class: "image-container" }
const _hoisted_7 = ["src"]
const _hoisted_8 = {
  key: 0,
  class: "eighteen-font-400w author-description"
}
const _hoisted_9 = { class: "eighteen-font-400w author-name" }
const _hoisted_10 = { class: "eighteen-font-400w description" }
const _hoisted_11 = { class: "author-post all-authors" }
const _hoisted_12 = { class: "all-authors-content" }
const _hoisted_13 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QuarterSquare = _resolveComponent("QuarterSquare")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      ($setup.authors.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.authors.slice(0, $setup.maxVisibleAuthors), (author, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: "author-post",
                onMouseover: ($event: any) => ($setup.hoverAuthor = author),
                onMouseleave: _cache[0] || (_cache[0] = ($event: any) => ($setup.hoverAuthor = null)),
                onClick: ($event: any) => ($setup.goToAuthorPage(author.pseudonym))
              }, [
                _createElementVNode("div", _hoisted_5, [
                  _cache[1] || (_cache[1] = _createElementVNode("div", { class: "background-overlay" }, null, -1)),
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("img", {
                      src: author.authorPhotoTempUrl,
                      alt: "Популярные авторы не загружены!",
                      class: "centered-image"
                    }, null, 8, _hoisted_7)
                  ]),
                  ($setup.hoverAuthor === author)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                        _createElementVNode("div", _hoisted_9, _toDisplayString(author.pseudonym), 1),
                        _createElementVNode("div", _hoisted_10, _toDisplayString(author.description), 1)
                      ]))
                    : _createCommentVNode("", true)
                ])
              ], 40, _hoisted_4))
            }), 128)),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _createVNode(_component_QuarterSquare, { label: "Все исполнители" })
              ])
            ])
          ]))
        : (_openBlock(), _createElementBlock("p", _hoisted_13, "Загрузка авторов..."))
    ])
  ]))
}