import {BaseApi} from "@/api/base/BaseApi";

const API_PREFIX = '/backend';

type SongSavingRequestFormData = {
    photo: File;
    song: Omit<BackendT['SongSavingRequest'], 'photo'>;
};

export class SongApi {
    private client: BaseApi;

    constructor() {
        this.client = new BaseApi();
    }

    public async fetchSong(songName: string, authorPseudonym: string): Promise<BackendT['SongResponse']> {
        try {
            return this.client.get<BackendT['SongResponse']>(API_PREFIX + '/song', {
                params: {
                    songName,
                    authorPseudonym,
                },
                headers: {
                    'Content-Type': 'application/json',
                }
            });
        } catch (error) {
            throw new Error(`An error occurred while fetching song: ${error}`);
        }
    }

    public async saveSong(requestBody: SongSavingRequestFormData): Promise<BackendT['SongResponse']> {
        try {
            const formData = new FormData();
            formData.append('photo', requestBody.photo, 'photo.jpg');
            formData.append('song', new Blob([JSON.stringify(requestBody.song)], {type: 'application/json'}));

            return this.client.post<BackendT['SongResponse']>(API_PREFIX + '/song', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
        } catch (error) {
            throw new Error(`Failed to save Album: ${error}`);
        }
    }

    public async fetchTopSongsByRating(
        countOfSongs: number,
        minRating: number,
        maxRating: number,
        sortDirection: string
    ): Promise<BackendT['SongPreviewResponse'][]> {
        try {
            const path = API_PREFIX + `/song/top-by-rating/${minRating}/${maxRating}/${countOfSongs}`;
            console.log('Request Path:', path);

            const response = this.client.get<BackendT['SongPreviewResponse'][]>(path, {
                params: {sortDirection},
                headers: {'Content-Type': 'application/json'}
            });

            console.log('API Response:', response);
            return response;
        } catch (error) {
            console.error('Failed to fetch top songs by rating:', error);
            throw new Error(`Failed to fetch top songs by rating: ${error}`);
        }
    }


    public async fetchSongsOrderedAndLimited(countOfSongs: string, sortDirection: string): Promise<BackendT['SongPreviewResponse'][]> {
        try {
            return this.client.get<BackendT['SongPreviewResponse'][]>(API_PREFIX + '/song/ordered-and-limited', {
                params: {
                    countOfSongs,
                    sortDirection
                },
                headers: {
                    'Content-Type': 'application/json',
                }
            });
        } catch (error) {
            throw new Error(`An error occurred while fetching songs ordered and limited: ${error}`);
        }
    }

    public async fetchSongsSearchData(
        genre: string,
        language: string,
        difficult: string,
        search: string,
        offset: number,
        limit: number
    ): Promise<BackendT['FretFlexPageOfDataSongSearchDataResponse']> {
        try {
            const path = API_PREFIX + `/song/search`;
            console.log('Request Path:', path);

            const response = this.client.get<BackendT['FretFlexPageOfDataSongSearchDataResponse']>(path, {
                params: {genre, language, difficult, search, offset, limit},
                headers: {'Content-Type': 'application/json'}
            });

            console.log('API Response:', response);
            return response;
        } catch (error) {
            console.error('Failed to fetch searched songs:', error);
            throw new Error(`Failed to fetch searched songs: ${error}`);
        }
    }

    public async fetchSongBlocks(): Promise<BackendT['SongBlock'][]> {
        try {
            return this.client.get<BackendT['SongBlock'][]>(API_PREFIX + '/song/blocks', {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
        } catch (error) {
            throw new Error(`An error occurred while fetching song blocks: ${error}`);
        }
    }

    public async fetchDifficultInStars(): Promise<BackendT['DifficultInStarsDto'] []> {
        try {
            return this.client.get<BackendT['DifficultInStarsDto'][]>(API_PREFIX + '/song/difficults', {});
        } catch (error) {
            throw new Error(`An error occurred while fetching difficult in stars: ${error}`);
        }
    }

    public async fetchSongLanguages(): Promise<BackendT['SongLanguage'][]> {
        try {
            return this.client.get<BackendT['SongLanguage'][]>(API_PREFIX + '/song/languages', {});
        } catch (error) {
            throw new Error(`An error occurred while fetching song languages: ${error}`);
        }
    }
}

export default new SongApi();
