import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import HomePage from '@/pages/HomePage.vue';
import AllGenresPage from '@/pages/AllGenresPage.vue';
import AllMusiciansPage from '@/pages/AllMusiciansPage.vue';
import ChordLibraryPage from '@/pages/ChordLibraryPage.vue';
import TunerPage from '@/pages/TunerPage.vue';
import SongPage from '@/pages/SongPage.vue';
import AddSongPage from '@/pages/AddSongPage.vue';
import SongsLibrary from '@/pages/SongsSearchPage.vue';
import RegisterPage from '@/pages/RegisterPage.vue';
import LoginPage from '@/pages/LoginPage.vue';
import { CookieManager } from "@/api/base/CookieManager";
import UploadChordPage from "@/pages/UploadChordPage.vue";
import UserPage from "@/pages/UserPage.vue";
import AuthorPage from "@/pages/AuthorPage.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'home',
        component: HomePage,
        meta: { requiresAuth: true },
    },
    {
        path: '/songsLibrary',
        name: 'songsLibrary',
        component: SongsLibrary,
        meta: { requiresAuth: true },
    },
    {
        path: '/allGenres',
        name: 'allGenres',
        component: AllGenresPage,
        meta: { requiresAuth: true },
    },
    {
        path: '/allMusicians',
        name: 'allMusicians',
        component: AllMusiciansPage,
        meta: { requiresAuth: true },
    },
    {
        path: '/chordLibrary',
        name: 'chordLibrary',
        component: ChordLibraryPage,
        meta: { requiresAuth: true },
    },
    {
        path: '/tuner',
        name: 'tuner',
        component: TunerPage,
        meta: { requiresAuth: true },
    },
    {
        path: '/addSong',
        name: 'addSong',
        component: AddSongPage,
        meta: { requiresAuth: true },
    },
    {
        path: '/my-profile',
        name: 'my-profile',
        component: UserPage,
        meta: { requiresAuth: true },
    },
    {
        path: '/register',
        name: 'register',
        component: RegisterPage,
        meta: { requiresAuth: false },
    },
    {
        path: '/login',
        name: 'login',
        component: LoginPage,
        meta: { requiresAuth: false },
    },
    {
        path: '/upload-chords',
        name: 'upload-chords',
        component: UploadChordPage,
        meta: { requiresAuth: true },
    },
    {
        path: '/songs/:songName/:authorPseudonym',
        name: 'songView',
        component: SongPage,
        meta: { requiresAuth: true },
    },
    {
        path: '/author/:pseudonym',
        name: 'authorProfile',
        component: AuthorPage,
        meta: { requiresAuth: true },
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    const token = CookieManager.getCookie('token');
    console.log('Token:', token);

    const isAuthenticated: boolean = !!token;

    if (to.matched.some(record => record.meta.requiresAuth) && !isAuthenticated) {
        if (to.name !== 'login') {
            next({ name: 'login' });
        } else {
            next();
        }
    } else if (isAuthenticated && to.name === 'login') {
        next({ name: 'home' });
    } else {
        next();
    }
});

export default router;