import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withModifiers as _withModifiers, vModelText as _vModelText, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "dots-wrapper" }
const _hoisted_2 = { class: "dots-container" }
const _hoisted_3 = { class: "collections" }
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Snackbar = _resolveComponent("Snackbar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(3, (dot) => {
        return _createElementVNode("span", {
          class: "dot",
          key: dot
        })
      }), 64)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.openModal && _ctx.openModal(...args)), ["stop"]))
        }, "Создать"),
        (_ctx.isModalVisible)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "modal-overlay",
              onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)))
            }, [
              _createElementVNode("div", {
                class: "modal-content",
                onClick: _cache[5] || (_cache[5] = _withModifiers(() => {}, ["stop"]))
              }, [
                _cache[9] || (_cache[9] = _createElementVNode("h3", null, "Добавить коллекцию", -1)),
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.newCollectionTitle) = $event)),
                  type: "text",
                  placeholder: "Название коллекции"
                }, null, 512), [
                  [_vModelText, _ctx.newCollectionTitle]
                ]),
                _createElementVNode("input", {
                  ref: "fileInput",
                  type: "file",
                  accept: "image/*",
                  onChange: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleFileChange && _ctx.handleFileChange(...args))),
                  class: "file-input"
                }, null, 544),
                _createElementVNode("button", {
                  onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.createCollection && _ctx.createCollection(...args)))
                }, "Создать"),
                _createElementVNode("button", {
                  onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)))
                }, "Отмена")
              ])
            ]))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.userCollections, (collection) => {
          return (_openBlock(), _createElementBlock("div", {
            key: collection.id,
            class: "collection-item",
            onClick: _withModifiers(($event: any) => (_ctx.toggle(collection.id)), ["stop"])
          }, [
            _createElementVNode("span", {
              class: _normalizeClass(["plus-icon", { 'checked-icon': _ctx.isSongInCollection(collection.id) }])
            }, null, 2),
            _createTextVNode(" " + _toDisplayString(collection.title), 1)
          ], 8, _hoisted_4))
        }), 128))
      ])
    ]),
    _createVNode(_component_Snackbar, {
      color: 'red',
      show: _ctx.showError,
      message: _ctx.errorMessage,
      "onUpdate:show": _cache[7] || (_cache[7] = ($event: any) => (_ctx.showError = $event))
    }, null, 8, ["show", "message"]),
    _createVNode(_component_Snackbar, {
      color: 'green',
      show: _ctx.showSuccess,
      message: _ctx.successMessage,
      "onUpdate:show": _cache[8] || (_cache[8] = ($event: any) => (_ctx.showSuccess = $event))
    }, null, 8, ["show", "message"])
  ]))
}