import {computed, ref} from 'vue';
import authorApi from '@/api/AuthorApi';
import albumApi from '@/api/AlbumApi';
import songApi from '@/api/SongApi';
import genreApi from "@/api/GenreApi";
import {AxiosError} from "axios";

type AuthorSavingRequestFormData = {
    photo: File;
    author: Omit<BackendT['AuthorSavingRequest'], 'photo'>;
};

type SongSavingRequestFormData = {
    photo: File;
    song: Omit<BackendT['SongSavingRequest'], 'photo'>;
};

type AlbumSavingRequestFormData = {
    photo: File;
    album: Omit<BackendT['AlbumSavingRequest'], 'photo'>;
};

const emptySongPhoto = new File([], "");
const emptyAuthorPhoto = new File([], "");
const emptyAlbumPhoto = new File([], "");
const currentStep = ref(0);
const authorsSearchQuery = ref('');
const albumsSearchQuery = ref('');
const authors = ref<BackendT['AuthorDynamicPreviewResponse'][]>([]);
const selectedAuthor = ref<BackendT['AuthorDynamicPreviewResponse'] | null>(null);
const albums = ref<BackendT['AlbumDynamicPreviewResponse'][]>([]);
const selectedAlbum = ref<BackendT['AlbumDynamicPreviewResponse'] | null>(null);
const genres = ref<BackendT['GenreResponse'][]>([]);
const songBlock = ref<BackendT['SongBlock'][]>([]);

const steps = ['Данные о песне', 'Аккорды песни'];

const newSongLines = ref<(BackendT['LinesWithChords'] & { chordsInput: string })[]>([{
    songBlock: 'INTRO',
    line: '',
    chordWithPositions: [],
    chordsInput: ''
}]);

const toggleSongBlock = (index: number) => {
    const currentBlock = newSongLines.value[index].songBlock;
    const songBlockValues = songBlock.value;
    const currentIndex = songBlockValues.indexOf(currentBlock);
    const nextIndex = (currentIndex + 1) % songBlockValues.length;
    newSongLines.value[index].songBlock = songBlockValues[nextIndex];
};

const addSongLine = () => {
    newSongLines.value.push({
        songBlock: "VERSE",
        line: '',
        chordWithPositions: [],
        chordsInput: ''
    });
};

const removeSongLine = () => {
    if (newSongLines.value.length > 1) {
        newSongLines.value.pop();
    }
};

const updateChordPositions = (line: BackendT['LinesWithChords'] & { chordsInput: string },
                              updatedChords: BackendT['ChordWithPosition'][]) => {
    line.chordWithPositions = updatedChords;
};

const newSong = ref<SongSavingRequestFormData>({
    photo: emptySongPhoto,
    song: {
        authorPseudonym: null,
        albumName: null,
        name: '',
        description: '',
        sampleYoutubeLink: '',
        difficultInStars: '',
        subgenreId: 0,
        tempoBPM: 0,
        havingObscene: false,
        text: {
            linesWithChords: [],
        },
    },
});

const yearsOfActivity = ref<BackendT['YearsOfActivity']>({
    start: '',
    end: '',
});

const safeYearsOfActivity = computed(() => {
    return newAuthor.value.author.yearsOfActivity || {start: '', end: ''};
});

const newAuthor = ref<AuthorSavingRequestFormData>({
    photo: emptyAuthorPhoto,
    author: {
        name: '',
        pseudonym: '',
        yearsOfActivity: yearsOfActivity.value,
        description: null,
        country: 'WITHOUT_COUNTRY',
        birthDate: null,
    },
});

const newAlbum = ref<AlbumSavingRequestFormData>({
    photo: emptyAlbumPhoto,
    album: {
        authorPseudonym: '',
        name: '',
        description: null,
        release: null,
    },
});

const showError = ref(false);
const showSuccess = ref(false);
const successMessage = ref('');
const errorMessage = ref('');
const isAuthorModalOpen = ref(false);
const isAlbumModalOpen = ref(false);

const isFirstStep = computed(() => currentStep.value === 0);
const isLastStep = computed(() => currentStep.value === steps.length - 1);
const isNextStepActive = computed(() => {
    if (currentStep.value === 0) {
        return selectedAuthor.value;
    }
    return true;
});

const currentStepTitle = computed(() => steps[currentStep.value]);

const fetchSubgenres = async () => {
    try {
        genres.value = await genreApi.fetchAllSongGenres();
        console.log('Загруженные жанры:', genres.value); // Данные должны быть корректно загружены
    } catch (error) {
        console.error('Ошибка при загрузке списка жанров:', error);
    }
};

const fetchSongBlocks = async () => {
    try {
        songBlock.value = await songApi.fetchSongBlocks();
        console.log('Загруженные блоки:', songBlock.value);
    } catch (error) {
        console.error('Ошибка при загрузке списка блоков песен:', error);
    }
};


const searchAuthors = async () => {
    if (!authorsSearchQuery.value) {
        authors.value = [];
        return;
    }

    try {
        const limit = 10;
        authors.value = await authorApi.fetchAuthorsByFirstLetters(authorsSearchQuery.value, limit);
    } catch (error) {
        console.error('Ошибка при поиске авторов:', error);
    }
};

const searchAlbums = async () => {
    if (!selectedAuthor.value || !albumsSearchQuery.value) {
        albums.value = [];
        return;
    }
    try {
        if (selectedAuthor.value.pseudonym != null) {
            albums.value = await albumApi.fetchAlbumsByAuthorAndNameFirstLetters(
                selectedAuthor.value.pseudonym,
                albumsSearchQuery.value
            );
        }
    } catch (error) {
        console.error('Ошибка при поиске альбомов:', error);
    }
};

const selectAuthor = (author: BackendT['AuthorDynamicPreviewResponse']) => {
    selectedAuthor.value = author;
    if (author.pseudonym != null) {
        authorsSearchQuery.value = author.pseudonym;
        newAlbum.value.album.authorPseudonym = author.pseudonym;
        newSong.value.song.authorPseudonym = author.pseudonym;
    }
    selectedAlbum.value = {id: 0, name: ''};
    albums.value = [];
    authors.value = [];
};

const selectAlbum = (album: BackendT['AlbumDynamicPreviewResponse']) => {
    selectedAlbum.value = album;
    albums.value = [];
    albumsSearchQuery.value = album.name;
    newSong.value.song.albumName = album.name;
};

const onAuthorInputChange = () => {
    if (selectedAuthor.value) {
        clearSelectedAuthor();
    }
    searchAuthors();
};

const onAlbumInputChange = () => {
    if (selectedAlbum.value) {
        clearSelectedAlbum();
    }
    searchAlbums();
};

const clearSelectedAuthor = () => {
    selectedAuthor.value = null;
    newAlbum.value.album.authorPseudonym = '';
    newSong.value.song.authorPseudonym = '';
    albums.value = [];
    albumsSearchQuery.value = '';
};

const clearSelectedAlbum = () => {
    selectedAlbum.value = null;
    newSong.value.song.albumName = '';
    albumsSearchQuery.value = '';
};


const openAlbumModal = () => {
    isAlbumModalOpen.value = true;
};

const goToNextStep = () => {
    if (!isNextStepActive.value) return;

    if (isLastStep.value) {
        saveSong();
    } else {
        currentStep.value++;
    }
}

const goToPreviousStep = () => {
    if (!isFirstStep.value) currentStep.value--;
}

const openAuthorModal = () => {
    isAuthorModalOpen.value = true;
}

const closeAuthorModal = () => {
    isAuthorModalOpen.value = false;
}

const saveSong = async () => {
    try {
        newSong.value.song.text.linesWithChords = newSongLines.value.map((line) => ({
            songBlock: line.songBlock,
            line: line.line,
            chordWithPositions: line.chordWithPositions,
        }));

        await songApi.saveSong(newSong.value);
        successMessage.value = 'Песня успешно сохранена';
        showSuccess.value = true;
        console.log('Song saved:', newSong.value);
    } catch (error) {
        const axiosError = error as AxiosError<ApiErrorResponse>;
        errorMessage.value = axiosError.response?.data?.message || 'Ошибка при сохранении песни.';
        showError.value = true;
        console.error('Ошибка при сохранении песни:', error);
    }
}

export {
    currentStep,
    authorsSearchQuery,
    albumsSearchQuery,
    authors,
    selectedAuthor,
    albums,
    selectedAlbum,
    songBlock,
    genres,
    steps,
    newSongLines,
    toggleSongBlock,
    addSongLine,
    removeSongLine,
    updateChordPositions,
    newSong,
    yearsOfActivity,
    safeYearsOfActivity,
    newAuthor,
    newAlbum,
    showError,
    showSuccess,
    successMessage,
    errorMessage,
    isAuthorModalOpen,
    isAlbumModalOpen,
    isFirstStep,
    isLastStep,
    isNextStepActive,
    currentStepTitle,
    fetchSongBlocks,
    fetchSubgenres,
    searchAuthors,
    searchAlbums,
    selectAuthor,
    selectAlbum,
    onAuthorInputChange,
    onAlbumInputChange,
    clearSelectedAuthor,
    clearSelectedAlbum,
    openAlbumModal,
    goToNextStep,
    goToPreviousStep,
    openAuthorModal,
    closeAuthorModal,
    saveSong
};