import {BaseApi} from "@/api/base/BaseApi";

const API_PREFIX = '/user'

export class UserApi {
    private client: BaseApi;

    constructor() {
        this.client = new BaseApi();
    }

    public async getUserInfo(): Promise<UserT['UserInfoResponse']> {
        try {
            return this.client.get<UserT['UserInfoResponse']>(API_PREFIX + '/info', {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        } catch (error) {
            throw new Error(`Failed to get user info: ${error}`);
        }
    }
}

export default new UserApi();
