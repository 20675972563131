<template>
  <div>
    <p v-if="genres.length === 0">Загрузка жанров...</p>
    <div class="grid-container" v-else>
      <div
          class="grid-item large"
          id="genre-1"
          @mouseover="hoverGenre = genres[0]"
          @mouseleave="hoverGenre = null"
      >
        <div class="grid-content GenrePost">
          <img
              :src="genres[0].cover || '/media/images/mockPhotos/temp_genre_cover.jpg'"
              alt="Обложка жанра"
              class="centered-image first-image"
          />
          <div class="GenreName">
            <div class="eighteen-font-400w">
              <a :href="'/genre/' + genres[0].name">{{ genres[0].name }}</a>
            </div>
          </div>
          <div class="Subgenres">
            <div v-for="subgenre in genres[0].subgenres" :key="subgenre.name">
              <a :href="'/subgenre/' + subgenre.name">{{ subgenre.name }}</a>
            </div>
          </div>
        </div>
      </div>

      <div
          v-for="(genre, index) in genres.slice(1, 5)"
          :key="genre.name"
          class="grid-item small"
          :id="'genre-' + (index + 2)"
          @mouseover="hoverGenre = genre"
          @mouseleave="hoverGenre = null"
      >
        <div class="grid-content GenrePost">
          <div class="GenreName">
            <div class="eighteen-font-400w">
              <a :href="'/genre/' + genre.name">{{ genre.name }}</a>
            </div>
          </div>
          <div class="Subgenres">
            <div v-for="subgenre in genre.subgenres" :key="subgenre.name">
              <a :href="'/subgenre/' + subgenre.name">{{ subgenre.name }}</a>
            </div>
          </div>
        </div>
      </div>

      <div
          v-for="(genre, index) in genres.slice(5, 9)"
          :key="genre.name"
          class="grid-item small"
          :id="'genre-' + (index + 6)"
          @mouseover="hoverGenre = genre"
          @mouseleave="hoverGenre = null"
      >
        <div class="grid-content GenrePost">
          <div class="GenreName">
            <div class="eighteen-font-400w">
              <a :href="'/genre/' + genre.name">{{ genre.name }}</a>
            </div>
          </div>
          <div class="Subgenres">
            <div v-for="subgenre in genre.subgenres" :key="subgenre.name">
              <a :href="'/subgenre/' + subgenre.name">{{ subgenre.name }}</a>
            </div>
          </div>
        </div>
      </div>

      <div
          class="grid-item large"
          id="genre-10"
          @mouseover="hoverGenre = genres[9]"
          @mouseleave="hoverGenre = null"
      >
        <div class="grid-content GenrePost">
          <img
              :src="genres[9].cover || '/media/images/mockPhotos/temp_genre_cover.jpg'"
              alt="Обложка жанра"
              class="centered-image first-image"
          />
          <div class="GenreName">
            <div class="eighteen-font-400w">
              <a :href="'/genre/' + genres[9].name">{{ genres[9].name }}</a>
            </div>
          </div>
          <div class="Subgenres">
            <div v-for="subgenre in genres[9].subgenres" :key="subgenre.name">
              <a :href="'/subgenre/' + subgenre.name">{{ subgenre.name }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue';
import genreApi from '@/api/GenreApi';

export default defineComponent({
  setup() {
    const genres = ref<BackendT['GenreResponse'][]>([]);
    const hoverGenre = ref<BackendT['GenreResponse'] | null>(null);

    const fetchAllSongGenres = async () => {
      try {
        genres.value = await genreApi.fetchAllSongGenres();
      } catch (error) {
        console.error('Ошибка загрузки жанров:', error);
      }
    };

    onMounted(() => {
      fetchAllSongGenres();
    });

    return {
      genres,
      hoverGenre,
    };
  },
});
</script>

<style scoped>
.GenreName a,
.Subgenres a {
  text-decoration: none;
  color: inherit;
}

.Subgenres a:hover {
  padding-left: 5px;
  transform: translateY(-1px);
}

.grid-container {
  position: relative;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0;
  width: 100%;
}

.grid-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid rgba(255, 255, 255, 0.1);
  pointer-events: none;
}

.grid-item {
  position: relative;
}

.grid-item::before {
  content: '';
  display: block;
  padding-top: 100%;
}

.grid-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: background-color 0.3s, color 0.3s;
  cursor: pointer;
  box-sizing: border-box;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.large {
  grid-column: span 2;
  grid-row: span 2;
}

.small {
  grid-column: span 1;
  grid-row: span 1;
}

#genre-1 {
  grid-column: 1 / 3;
  grid-row: 1 / 3;
}

#genre-10 {
  grid-column: 3 / 5;
  grid-row: 3 / 5;
}

.GenrePost:hover {
  background-color: rgba(255, 126, 7, 1);
}

.GenrePost:hover .GenreName,
.GenrePost:hover .Subgenres,
.GenrePost:hover,
.GenrePost:hover .eighteen-font-400w {
  color: black;
}

.GenrePost:hover img.first-image {
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0.9);
}

.centered-image {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40%;
  height: auto;
  transform: translate(-50%, -50%);
  transition: transform 0.3s, border-radius 0.3s;
  object-fit: cover;
}

.GenreName {
  position: absolute;
  bottom: 25px;
  left: 5px;
  padding-left: 4%;
  padding-bottom: 4%;
  color: rgba(255, 255, 255, 1);
  transition: color 0.3s;
}

.GenrePost:hover .GenreName {
  display: none;
}

.Subgenres {
  position: absolute;
  bottom: 25px;
  left: 5px;
  padding-left: 4%;
  padding-bottom: 4%;
  color: white;
  font-size: 17px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: color 0.3s, transform 0.3s;
  transform: translateY(20px);
  opacity: 0;
}

.GenrePost:hover .Subgenres {
  transform: translateY(0);
  opacity: 1;
}

@media (max-width: 768px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(5, 1fr);
  }

  #genre-1,
  #genre-10 {
    grid-column: span 2;
  }

  #genre-1 {
    grid-row: 1 / 3;
  }

  #genre-10 {
    grid-row: 5 / 7;
  }
}
</style>
