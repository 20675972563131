import { defineComponent as _defineComponent } from 'vue'
import { vModelText as _vModelText, withModifiers as _withModifiers, withKeys as _withKeys, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "chords-editor" }
const _hoisted_2 = ["onKeydown", "placeholder"]
const _hoisted_3 = {
  key: 0,
  class: "chords-container"
}
const _hoisted_4 = ["onMousedown"]
const _hoisted_5 = { class: "chord" }
const _hoisted_6 = ["onClick"]

import { computed, onMounted, ref, watch } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ChordsEditor',
  props: {
    line: {},
    index: {}
  },
  emits: ["update-chords"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const chords = ref<BackendT['ChordWithPosition'][]>([]);
const chordsInput = ref<string>(props.line.chordsInput);
const input = ref<HTMLInputElement | null>(null);
const inputWrapper = ref<HTMLDivElement | null>(null);
const isDragging = ref(false);
const dragIndex = ref<number | null>(null);
const dragStartX = ref<number | null>(null);
const chordStartX = ref<number | null>(null);

const showPlaceholder = computed(() => !chordsInput.value && chords.value.length === 0);

const focusInput = () => {
  input.value?.focus();
};

const handleSpace = () => {
  const chord = chordsInput.value.trim();
  if (chord) {
    let position = Math.floor(input.value!.selectionStart || 0);

    if (chords.value.length > 0) {
      const lastChord = chords.value[chords.value.length - 1];
      if (position <= lastChord.position) {
        position = lastChord.position + lastChord.chord.length;
      }
    }
    chords.value.push({ chord, position });
    chordsInput.value = '';
    emit('update-chords', chords.value);
  }
};

const getCharWidth = () => {
  const span = document.createElement('span');
  span.style.visibility = 'hidden';
  span.style.position = 'absolute';
  span.style.whiteSpace = 'pre';
  span.style.font = getComputedStyle(input.value!).font;
  span.textContent = 'M';
  document.body.appendChild(span);
  const charWidth = span.getBoundingClientRect().width;
  document.body.removeChild(span);
  return charWidth;
};

const startDragging = (index: number, event: MouseEvent) => {
  isDragging.value = true;
  dragIndex.value = index;
  dragStartX.value = event.clientX;
  chordStartX.value = chords.value[index].position;
  window.addEventListener('mousemove', onMouseMove);
  window.addEventListener('mouseup', onMouseUp);
};

const onMouseMove = (event: MouseEvent) => {
  if (!isDragging.value || dragIndex.value === null || !chords.value[dragIndex.value]) return;

  const deltaX = event.clientX - (dragStartX.value || 0);
  const charWidth = getCharWidth();
  const chordLength = chords.value[dragIndex.value].chord.length;
  let newPosition = Math.round((chordStartX.value || 0) + deltaX / charWidth);

  const maxWidth = Math.floor((inputWrapper.value?.clientWidth || 0) / charWidth) - chordLength;

  const leftBoundary = dragIndex.value === 0 ? 0 : chords.value[dragIndex.value - 1].position + chords.value[dragIndex.value - 1].chord.length;
  const rightBoundary = dragIndex.value === chords.value.length - 1 ? maxWidth : chords.value[dragIndex.value + 1].position - chordLength;

  if (newPosition < leftBoundary) {
    let i = dragIndex.value - 1;
    while (i >= 0 && newPosition < chords.value[i].position + chords.value[i].chord.length) {
      i--;
    }
    newPosition = Math.max(newPosition, i >= 0 ? chords.value[i].position + chords.value[i].chord.length : 0);
  } else if (newPosition > rightBoundary) {
    let i = dragIndex.value + 1;
    while (i < chords.value.length && newPosition + chordLength > chords.value[i].position) {
      i++;
    }
    newPosition = Math.min(newPosition, i < chords.value.length ? chords.value[i].position - chordLength : maxWidth);
  }

  chords.value[dragIndex.value].position = newPosition;
  emit('update-chords', chords.value);
};

const onMouseUp = () => {
  isDragging.value = false;
  window.removeEventListener('mousemove', onMouseMove);
  window.removeEventListener('mouseup', onMouseUp);
};

const onInput = () => {
  emit('update-chords', chords.value);
};

const onBlur = () => {
  if (!isDragging.value) emit('update-chords', chords.value);
};

const removeChord = (index: number) => {
  chords.value.splice(index, 1);
  emit('update-chords', chords.value);
};

watch(chords, (newChords) => {
  if (!isDragging.value) emit('update-chords', newChords);
});

onMounted(() => {
  chordsInput.value = props.line.chordsInput;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "input-field",
      onClick: focusInput,
      ref_key: "inputWrapper",
      ref: inputWrapper
    }, [
      _withDirectives(_createElementVNode("input", {
        ref_key: "input",
        ref: input,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((chordsInput).value = $event)),
        onKeydown: _withKeys(_withModifiers(handleSpace, ["prevent"]), ["space"]),
        onBlur: onBlur,
        onInput: onInput,
        class: "chords-input",
        type: "text",
        placeholder: showPlaceholder.value ? 'Введите аккорды...' : ''
      }, null, 40, _hoisted_2), [
        [_vModelText, chordsInput.value]
      ]),
      (chords.value.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(chords.value, (chord, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: "chord-wrapper",
                style: _normalizeStyle({ left: `${chord.position * getCharWidth()}px` }),
                onMousedown: _withModifiers(($event: any) => (startDragging(index, $event)), ["prevent"])
              }, [
                _createElementVNode("span", _hoisted_5, _toDisplayString(chord.chord), 1),
                _createElementVNode("span", {
                  class: "delete-chord",
                  onClick: _withModifiers(($event: any) => (removeChord(index)), ["stop"])
                }, "×", 8, _hoisted_6)
              ], 44, _hoisted_4))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ], 512)
  ]))
}
}

})