<template>
  <div class="chord-form-container">
    <Snackbar :color="'red'" :show="showError" :message="errorMessage" @update:show="showError = $event"/>
    <Snackbar :color="'green'" :show="showSuccess" :message="successMessage" @update:show="showSuccess = $event"/>

    <div class="form-section">
      <div class="form-group">
        <label for="chord-files">Выберите файлы аккордов и изображения</label>
        <input
            id="chord-files"
            type="file"
            multiple
            accept=".txt, .png, .jpg, .jpeg, .gif"
            @change="handleFileChange"
        />
      </div>
      <div class="form-group">
        <label class="custom-switch">
          <input type="checkbox" v-model="needUpdate" class="switch-input"/>
          <span class="switch-slider"></span>
          Необходимость обновления
        </label>
      </div>
      <ul v-if="chordPreviews.length" class="file-list">
        <li v-for="(chord, index) in chordPreviews" :key="index" class="file-item">
          {{ chord.name }}
        </li>
      </ul>
      <button @click="saveChords" class="save-button">Сохранить аккорды и изображения</button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import ChordApi from '@/api/ChordApi';
import Snackbar from '@/components/common/Snackbar.vue';

const chordFiles = ref<File[]>([]);
const chordPreviews = ref<{ name: string }[]>([]);
const showError = ref(false);
const showSuccess = ref(false);
const errorMessage = ref('');
const successMessage = ref('');
const needUpdate = ref(false);

const handleFileChange = (event: Event) => {
  const target = event.target as HTMLInputElement;
  if (target.files) {
    chordFiles.value = Array.from(target.files);
  }
};

watch(chordFiles, (newFiles) => {
  chordPreviews.value = newFiles.map(file => ({ name: file.name }));
});

const saveChords = async () => {
  const formData = new FormData();
  chordFiles.value.forEach(file => formData.append('chords', file));

  try {
    await ChordApi.saveChords(chordFiles.value, needUpdate.value);
    successMessage.value = 'Аккорды и изображения успешно сохранены';
    showSuccess.value = true;
    chordFiles.value = [];
    chordPreviews.value = [];
  } catch (error) {
    showError.value = true;
    errorMessage.value = 'Ошибка при сохранении аккордов и изображений';
    console.error('Ошибка при сохранении аккордов и изображений:', error);
  }
};
</script>

<style scoped>
.chord-form-container {
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-color: #121212;
  padding: 20px;
  border-radius: 8px;
}

.form-section {
  background-color: #1c1c1c;
  padding: 20px;
  border-radius: 8px;
  color: #ffffff;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 10px;
  font-size: 1rem;
  color: #ffffff;
}

input[type="file"] {
  width: 100%;
  padding: 10px;
  background-color: #333;
  color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.file-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.file-item {
  padding: 10px;
  background-color: #333;
  border-radius: 5px;
  margin-bottom: 10px;
  color: #ffffff;
}

.save-button {
  padding: 10px 20px;
  background-color: #ff6600;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  width: 100%;
}

.save-button:hover {
  background-color: #e65c00;
}

.custom-switch {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.switch-input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch-slider {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
  background-color: #ff6600;
  border-radius: 25px;
  margin-right: 10px;
  transition: background-color 0.3s ease;
}

.switch-slider::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 2px;
  width: 21px;
  height: 21px;
  background-color: black;
  border-radius: 50%;
  transform: translateY(-50%);
  transition: transform 0.3s ease;
}

.switch-input:checked + .switch-slider::before {
  transform: translate(25px, -50%);
}
</style>