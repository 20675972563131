import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "chord-form-container" }
const _hoisted_2 = { class: "form-section" }
const _hoisted_3 = { class: "form-group" }
const _hoisted_4 = { class: "form-group" }
const _hoisted_5 = { class: "custom-switch" }
const _hoisted_6 = {
  key: 0,
  class: "file-list"
}

import { ref, watch } from 'vue';
import ChordApi from '@/api/ChordApi';
import Snackbar from '@/components/common/Snackbar.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'AddChords',
  setup(__props) {

const chordFiles = ref<File[]>([]);
const chordPreviews = ref<{ name: string }[]>([]);
const showError = ref(false);
const showSuccess = ref(false);
const errorMessage = ref('');
const successMessage = ref('');
const needUpdate = ref(false);

const handleFileChange = (event: Event) => {
  const target = event.target as HTMLInputElement;
  if (target.files) {
    chordFiles.value = Array.from(target.files);
  }
};

watch(chordFiles, (newFiles) => {
  chordPreviews.value = newFiles.map(file => ({ name: file.name }));
});

const saveChords = async () => {
  const formData = new FormData();
  chordFiles.value.forEach(file => formData.append('chords', file));

  try {
    await ChordApi.saveChords(chordFiles.value, needUpdate.value);
    successMessage.value = 'Аккорды и изображения успешно сохранены';
    showSuccess.value = true;
    chordFiles.value = [];
    chordPreviews.value = [];
  } catch (error) {
    showError.value = true;
    errorMessage.value = 'Ошибка при сохранении аккордов и изображений';
    console.error('Ошибка при сохранении аккордов и изображений:', error);
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(Snackbar, {
      color: 'red',
      show: showError.value,
      message: errorMessage.value,
      "onUpdate:show": _cache[0] || (_cache[0] = ($event: any) => (showError.value = $event))
    }, null, 8, ["show", "message"]),
    _createVNode(Snackbar, {
      color: 'green',
      show: showSuccess.value,
      message: successMessage.value,
      "onUpdate:show": _cache[1] || (_cache[1] = ($event: any) => (showSuccess.value = $event))
    }, null, 8, ["show", "message"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[3] || (_cache[3] = _createElementVNode("label", { for: "chord-files" }, "Выберите файлы аккордов и изображения", -1)),
        _createElementVNode("input", {
          id: "chord-files",
          type: "file",
          multiple: "",
          accept: ".txt, .png, .jpg, .jpeg, .gif",
          onChange: handleFileChange
        }, null, 32)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("label", _hoisted_5, [
          _withDirectives(_createElementVNode("input", {
            type: "checkbox",
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((needUpdate).value = $event)),
            class: "switch-input"
          }, null, 512), [
            [_vModelCheckbox, needUpdate.value]
          ]),
          _cache[4] || (_cache[4] = _createElementVNode("span", { class: "switch-slider" }, null, -1)),
          _cache[5] || (_cache[5] = _createTextVNode(" Необходимость обновления "))
        ])
      ]),
      (chordPreviews.value.length)
        ? (_openBlock(), _createElementBlock("ul", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(chordPreviews.value, (chord, index) => {
              return (_openBlock(), _createElementBlock("li", {
                key: index,
                class: "file-item"
              }, _toDisplayString(chord.name), 1))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("button", {
        onClick: saveChords,
        class: "save-button"
      }, "Сохранить аккорды и изображения")
    ])
  ]))
}
}

})