import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, isRef as _isRef, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelSelect as _vModelSelect, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "top-bar" }
const _hoisted_3 = { class: "step-section" }
const _hoisted_4 = { class: "step-display" }
const _hoisted_5 = { class: "button-section" }
const _hoisted_6 = ["disabled"]
const _hoisted_7 = ["disabled"]
const _hoisted_8 = { class: "form-section" }
const _hoisted_9 = { class: "input-block" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { class: "form-group" }
const _hoisted_12 = {
  key: 0,
  class: "author-list"
}
const _hoisted_13 = ["onClick"]
const _hoisted_14 = { class: "form-group" }
const _hoisted_15 = ["disabled"]
const _hoisted_16 = {
  key: 0,
  class: "album-list"
}
const _hoisted_17 = ["onClick"]
const _hoisted_18 = { class: "form-group" }
const _hoisted_19 = { class: "form-group" }
const _hoisted_20 = { class: "form-group" }
const _hoisted_21 = ["disabled"]
const _hoisted_22 = ["value"]
const _hoisted_23 = { class: "form-group" }
const _hoisted_24 = {
  key: 0,
  class: "error-message"
}
const _hoisted_25 = { class: "form-group" }
const _hoisted_26 = { key: 1 }
const _hoisted_27 = { class: "form-group" }
const _hoisted_28 = { class: "button-group" }
const _hoisted_29 = { class: "block-selector-container" }
const _hoisted_30 = ["onClick"]
const _hoisted_31 = { class: "chords-editor-container" }
const _hoisted_32 = ["onUpdate:modelValue"]

import {computed, onMounted, ref} from 'vue';
import ChordsEditor from "@/components/add-song/ChordsEditor.vue";
import Snackbar from "@/components/common/Snackbar.vue";
import {
  addSongLine,
  albums,
  albumsSearchQuery,
  authors,
  authorsSearchQuery,
  currentStep,
  currentStepTitle,
  errorMessage,
  fetchSongBlocks,
  fetchSubgenres,
  genres,
  goToNextStep,
  goToPreviousStep,
  isFirstStep,
  isLastStep,
  isNextStepActive,
  newSong,
  newSongLines,
  onAlbumInputChange,
  onAuthorInputChange,
  openAlbumModal,
  openAuthorModal,
  removeSongLine,
  saveSong,
  selectAlbum,
  selectAuthor,
  selectedAlbum,
  selectedAuthor,
  showError,
  showSuccess,
  successMessage,
  toggleSongBlock,
  updateChordPositions
} from "@/components/add-song/modal/AddSongFormStates";
import AuthorModalForm from "@/components/add-song/modal/AuthorModalForm.vue";
import AlbumModalForm from "@/components/add-song/modal/AlbumModalForm.vue";
import ImageUploadComponent from "@/components/common/ImageUploadComponent.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'AddSongForm',
  setup(__props) {

onMounted(() => {
  fetchSubgenres();
  fetchSongBlocks()
});

const difficultInStarsError = ref<string | null>(null);

const validateAndCleanStars = () => {
  let value = newSong.value.song.difficultInStars;
  const cleanedValue = value.replace(/[^*]/g, '');
  if (cleanedValue !== value) {
    newSong.value.song.difficultInStars = cleanedValue;
  }
  difficultInStarsError.value = cleanedValue.length >= 1 && cleanedValue.length <= 5 ? null : 'Поле должно содержать от 1 до 5 звездочек';
};

const formattedSubgenres = computed(() => {
  if (!genres.value.length) {
    console.error("Данные genres не загружены");
    return [];
  }
  return genres.value.flatMap((genre) => {
    if (!genre.subgenres || !genre.subgenres.length) {
      console.warn(`Нет поджанров для жанра: ${genre.name}`);
      return [];
    }
    return genre.subgenres.map((subgenre) => ({
      id: subgenre.id,
      name: subgenre.name
    }));
  });
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(Snackbar, {
      color: 'red',
      show: _unref(showError),
      message: _unref(errorMessage),
      "onUpdate:show": _cache[0] || (_cache[0] = ($event: any) => (showError.value = $event))
    }, null, 8, ["show", "message"]),
    _createVNode(Snackbar, {
      color: 'green',
      show: _unref(showSuccess),
      message: _unref(successMessage),
      "onUpdate:show": _cache[1] || (_cache[1] = ($event: any) => (showSuccess.value = $event))
    }, null, 8, ["show", "message"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, _toDisplayString(_unref(currentStepTitle)), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("button", {
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_unref(goToPreviousStep) && _unref(goToPreviousStep)(...args))),
          disabled: _unref(isFirstStep),
          class: "back-button"
        }, " Назад ", 8, _hoisted_6),
        _createElementVNode("button", {
          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_unref(goToNextStep) && _unref(goToNextStep)(...args))),
          disabled: !_unref(isNextStepActive),
          class: "forward-button"
        }, _toDisplayString(_unref(isLastStep) ? 'Сохранить' : 'Далее'), 9, _hoisted_7)
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, [
        (_unref(currentStep) === 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_isRef(authorsSearchQuery) ? (authorsSearchQuery).value = $event : null)),
                  placeholder: "Исполнитель",
                  onInput: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_unref(onAuthorInputChange) && _unref(onAuthorInputChange)(...args))),
                  class: "search-field"
                }, null, 544), [
                  [_vModelText, _unref(authorsSearchQuery)]
                ]),
                (_unref(authors).length)
                  ? (_openBlock(), _createElementBlock("ul", _hoisted_12, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(authors), (author) => {
                        return (_openBlock(), _createElementBlock("li", {
                          key: author.id,
                          onClick: ($event: any) => (_unref(selectAuthor)(author)),
                          class: "author-item"
                        }, _toDisplayString(author.pseudonym), 9, _hoisted_13))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true),
                (!_unref(authors).length && !_unref(selectedAuthor) && _unref(authorsSearchQuery))
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 1,
                      onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_unref(openAuthorModal) && _unref(openAuthorModal)(...args))),
                      class: "create-author-button"
                    }, " Создать автора "))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_14, [
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (_isRef(albumsSearchQuery) ? (albumsSearchQuery).value = $event : null)),
                  placeholder: "Альбом",
                  disabled: !_unref(selectedAuthor),
                  onInput: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_unref(onAlbumInputChange) && _unref(onAlbumInputChange)(...args))),
                  class: "album-field"
                }, null, 40, _hoisted_15), [
                  [_vModelText, _unref(albumsSearchQuery)]
                ]),
                (_unref(albums).length)
                  ? (_openBlock(), _createElementBlock("ul", _hoisted_16, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(albums), (album) => {
                        return (_openBlock(), _createElementBlock("li", {
                          key: album.id,
                          onClick: ($event: any) => (_unref(selectAlbum)(album)),
                          class: "album-item"
                        }, _toDisplayString(album.name), 9, _hoisted_17))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true),
                (_unref(selectedAuthor) && !_unref(albums).length && !_unref(selectedAlbum) && _unref(albumsSearchQuery))
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 1,
                      onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_unref(openAlbumModal) && _unref(openAlbumModal)(...args))),
                      class: "create-album-button"
                    }, " Создать альбом "))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_18, [
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_unref(newSong).song.name) = $event)),
                  placeholder: "Название песни",
                  class: "song-field"
                }, null, 512), [
                  [_vModelText, _unref(newSong).song.name]
                ])
              ]),
              _createElementVNode("div", _hoisted_19, [
                _withDirectives(_createElementVNode("input", {
                  type: "number",
                  "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_unref(newSong).song.tempoBPM) = $event)),
                  placeholder: "Темп (bpm)",
                  class: "song-field"
                }, null, 512), [
                  [_vModelText, _unref(newSong).song.tempoBPM]
                ])
              ]),
              _createElementVNode("div", _hoisted_20, [
                _withDirectives(_createElementVNode("select", {
                  "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_unref(newSong).song.subgenreId) = $event)),
                  class: "song-field",
                  disabled: !formattedSubgenres.value.length
                }, [
                  _cache[20] || (_cache[20] = _createElementVNode("option", {
                    value: "",
                    disabled: ""
                  }, "Жанр", -1)),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(formattedSubgenres.value, (subgenre) => {
                    return (_openBlock(), _createElementBlock("option", {
                      key: subgenre.id,
                      value: subgenre.id
                    }, _toDisplayString(subgenre.name), 9, _hoisted_22))
                  }), 128))
                ], 8, _hoisted_21), [
                  [_vModelSelect, _unref(newSong).song.subgenreId]
                ])
              ]),
              _createElementVNode("div", _hoisted_23, [
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_unref(newSong).song.difficultInStars) = $event)),
                  placeholder: "Уровень сложности",
                  onInput: validateAndCleanStars,
                  class: "song-field"
                }, null, 544), [
                  [_vModelText, _unref(newSong).song.difficultInStars]
                ]),
                (difficultInStarsError.value)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_24, _toDisplayString(difficultInStarsError.value), 1))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_25, [
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_unref(newSong).song.sampleYoutubeLink) = $event)),
                  placeholder: "Ссылка на кавер",
                  class: "song-field"
                }, null, 512), [
                  [_vModelText, _unref(newSong).song.sampleYoutubeLink]
                ])
              ]),
              _createVNode(ImageUploadComponent, {
                title: 'Обложка песни',
                description: 'Максимальное разрешение: 1500 x 1500. Формат: PNG, JPEG, JPG.',
                modelValue: _unref(newSong).photo,
                "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_unref(newSong).photo) = $event))
              }, null, 8, ["modelValue"])
            ]))
          : _createCommentVNode("", true),
        (_unref(currentStep) === 1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
              _createElementVNode("div", _hoisted_27, [
                _withDirectives(_createElementVNode("textarea", {
                  "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_unref(newSong).song.description) = $event)),
                  placeholder: "Описание песни",
                  class: "song-field"
                }, null, 512), [
                  [_vModelText, _unref(newSong).song.description]
                ])
              ]),
              _createElementVNode("div", _hoisted_28, [
                _createElementVNode("button", {
                  onClick: _cache[17] || (_cache[17] = 
//@ts-ignore
(...args) => (_unref(addSongLine) && _unref(addSongLine)(...args))),
                  class: "add-remove-button"
                }, "+"),
                _createElementVNode("button", {
                  onClick: _cache[18] || (_cache[18] = 
//@ts-ignore
(...args) => (_unref(removeSongLine) && _unref(removeSongLine)(...args))),
                  class: "add-remove-button"
                }, "-")
              ]),
              _createElementVNode("form", {
                onSubmit: _cache[19] || (_cache[19] = _withModifiers(
//@ts-ignore
(...args) => (_unref(saveSong) && _unref(saveSong)(...args)), ["prevent"]))
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(newSongLines), (line, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: index,
                    class: "line-row"
                  }, [
                    _createElementVNode("div", _hoisted_29, [
                      _createElementVNode("button", {
                        type: "button",
                        onClick: ($event: any) => (_unref(toggleSongBlock)(index)),
                        class: "block-selector"
                      }, _toDisplayString(line.songBlock), 9, _hoisted_30)
                    ]),
                    _createElementVNode("div", _hoisted_31, [
                      _createVNode(ChordsEditor, {
                        line: line,
                        index: index,
                        onUpdateChords: ($event: any) => (_unref(updateChordPositions)(line, $event))
                      }, null, 8, ["line", "index", "onUpdateChords"]),
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": ($event: any) => ((line.line) = $event),
                        placeholder: "Строка текста песни",
                        class: "song-line-field"
                      }, null, 8, _hoisted_32), [
                        [_vModelText, line.line]
                      ])
                    ])
                  ]))
                }), 128))
              ], 32)
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createVNode(AuthorModalForm),
    _createVNode(AlbumModalForm)
  ]))
}
}

})