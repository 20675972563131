import { BaseApi } from "@/api/base/BaseApi";
import { CookieManager } from "@/api/base/CookieManager";

const API_PREFIX = '/auth';

export class AuthApi {
    private client = new BaseApi();

    async signUpUser(requestBody: { photo: File; user: Omit<UserT['UserRegistrationRequest'], 'photo'> }): Promise<UserT['JwtAuthenticationResponse']> {
        const formData = new FormData();
        formData.append('photo', requestBody.photo, 'photo.jpg');
        formData.append('user', new Blob([JSON.stringify(requestBody.user)], { type: 'application/json' }));
        return this.client.post(API_PREFIX + '/sign-up', formData, { headers: { 'Content-Type': 'multipart/form-data' } });
    }

    async signInUser(requestBody: UserT['UserSignInRequest']): Promise<UserT['JwtAuthenticationResponse']> {
        const response = await this.client.post<UserT['JwtAuthenticationResponse']>(API_PREFIX + '/sign-in', requestBody);
        if (response.token) CookieManager.setCookie('token', response.token);
        return response;
    }
}

export default new AuthApi()