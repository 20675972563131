import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "difficulty" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(5, (star) => {
      return _createElementVNode("span", {
        key: star,
        class: _normalizeClass({
        'star': true,
        'orange-star': star <= _ctx.rating,
        'gray-star': star > _ctx.rating
      })
      }, null, 2)
    }), 64))
  ]))
}