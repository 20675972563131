import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "song-display" }
const _hoisted_2 = { class: "song-content" }
const _hoisted_3 = { class: "eighteen-font-400w chords-line" }
const _hoisted_4 = { class: "eighteen-font-400w text-line" }
const _hoisted_5 = { class: "chord-images-container" }
const _hoisted_6 = ["src", "alt"]

import {ref, computed} from 'vue';

type TextOfSongResponse = {
  linesWithChords: BackendT['LinesWithChordsResponse'][];
};


export default /*@__PURE__*/_defineComponent({
  __name: 'SongWithChordsDisplay',
  props: {
    songData: {}
  },
  setup(__props: any) {

const props = __props;

const linesWithChords = ref<BackendT['LinesWithChordsResponse'][]>(props.songData.linesWithChords);

const uniqueChords = computed(() => {
  const chordMap = new Map<string, BackendT['ChordWithPositionResponse']>();
  linesWithChords.value.forEach(line => {
    line.chordWithPositions.forEach(chord => {
      if (!chordMap.has(chord.chord)) {
        chordMap.set(chord.chord, chord);
      }
    });
  });
  return Array.from(chordMap.values());
});

const getChordsLine = (chordWithPositions: BackendT['ChordWithPositionResponse'][]): string => {
  let chordsLine = '';
  const maxPosition = chordWithPositions.reduce((max, chord) => Math.max(max, chord.position), 0);

  for (let i = 0; i <= maxPosition; i++) {
    const chordAtPosition = chordWithPositions.find((chord) => chord.position === i);
    if (chordAtPosition) {
      chordsLine += chordAtPosition.chord;
    } else {
      chordsLine += ' ';
    }
  }

  return chordsLine;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(linesWithChords.value, (lineWithChords, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "song-line-block"
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("pre", null, _toDisplayString(getChordsLine(lineWithChords.chordWithPositions)), 1)
          ]),
          _createElementVNode("div", _hoisted_4, _toDisplayString(lineWithChords.line), 1)
        ]))
      }), 128))
    ]),
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "divider" }, null, -1)),
    _createElementVNode("div", _hoisted_5, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(uniqueChords.value, (chordImage, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "chord-image"
        }, [
          _createElementVNode("img", {
            src: chordImage.fingeringTempUrl,
            alt: chordImage.chord
          }, null, 8, _hoisted_6)
        ]))
      }), 128))
    ])
  ]))
}
}

})