import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, renderSlot as _renderSlot, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "dropdown-options"
}
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "dropdown-container",
    onMouseenter: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.openDropdown && _ctx.openDropdown(...args))),
    onMouseleave: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.closeDropdownWithDelay && _ctx.closeDropdownWithDelay(...args)))
  }, [
    _createElementVNode("div", {
      class: "dropdown-selected",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleDropdown && _ctx.toggleDropdown(...args)))
    }, [
      _createElementVNode("span", null, _toDisplayString(_ctx.selectedLabel || _ctx.placeholder), 1),
      _cache[3] || (_cache[3] = _createElementVNode("svg", {
        class: "dropdown-icon",
        width: "12",
        height: "8",
        viewBox: "0 0 12 8",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }, [
        _createElementVNode("path", {
          d: "M1 1.5L6 6.5L11 1.5",
          stroke: "#FF8800",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        })
      ], -1))
    ]),
    (_ctx.isOpen)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
            return (_openBlock(), _createElementBlock("li", {
              key: option.value,
              onClick: ($event: any) => (_ctx.selectOption(option))
            }, [
              _renderSlot(_ctx.$slots, "option", { option: option }, () => [
                _createTextVNode(_toDisplayString(option.label), 1)
              ], true)
            ], 8, _hoisted_2))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 32))
}