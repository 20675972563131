<template>
  <div
      class="square-container"
      @mouseover="startAnimation"
      @mouseleave="resetAnimation"
  >
    <div
        class="square-icon"
        v-for="index in 4"
        :key="index"
        :style="{
        zIndex: zOrder[index - 1],
        transform: `translate(${translateX[index - 1]}, ${translateY[index - 1]}) scale(0.8)`
      }"
    >
      <img src="../../assets/icons/svg/rectangle.svg" alt="Квадратик" class="centered-image" />
    </div>
    <div class="arrow-icon">
      <img src="../../assets/icons/svg/orange_arrow.svg" alt="Стрелка" class="arrow-image" />
    </div>
    <div class="eighteen-font-400w label">{{ label }}</div>
  </div>
</template>

<script>
export default {
  name: 'QuarterSquare',
  props: {
    label: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      translateX: ['calc(50% - 7%)', 'calc(-50% + 7%)', 'calc(50% - 7%)', 'calc(-50% + 7%)'], // Смещение с учетом размера квадратиков
      translateY: ['calc(-50% + 7%)', 'calc(-50% + 7%)', 'calc(50% - 7%)', 'calc(50% - 7%)'], // Смещение с учетом размера квадратиков
      zOrder: [0, 1, 2, 3],
      isAnimating: false,
    };
  },
  methods: {
    startAnimation() {
      if (!this.isAnimating) {
        this.isAnimating = true;
        this.translateX = ['calc(-50% + 7%)', 'calc(-50% + 7%)', 'calc(50% - 7%)', 'calc(50% - 7%)'];
        this.translateY = ['calc(50% - 7%)', 'calc(-50% + 7%)', 'calc(-50% + 7%)', 'calc(50% - 7%)'];
        this.zOrder = [3, 0, 1, 2];
      }
    },
    resetAnimation() {
      if (this.isAnimating) {
        this.isAnimating = false;
        this.translateX = ['calc(50% - 7%)', 'calc(-50% + 7%)', 'calc(50% - 7%)', 'calc(-50% + 7%)'];
        this.translateY = ['calc(-50% + 7%)', 'calc(-50% + 7%)', 'calc(50% - 7%)', 'calc(50% - 7%)'];
        this.zOrder = [0, 1, 2, 3];
      }
    }
  }
};
</script>

<style scoped>
.square-container {
  width: 100%; /* Занимает всю ширину родителя */
  height: 100%; /* Занимает всю высоту родителя */
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Защита от выхода за пределы */
}

.square-icon {
  width: 25%; /* Размер квадратиков в процентах */
  height: 25%;
  position: absolute;
  transition: transform 0.3s ease;
}

.centered-image {
  width: 100%;
  height: 100%;
}

.arrow-icon {
  position: absolute;
  bottom: 5%; /* Адаптивное позиционирование */
  right: 5%; /* Адаптивное позиционирование */
  width: 10%; /* Адаптивный размер стрелки */
  height: auto; /* Сохраняем пропорции */
  max-width: 40px; /* Ограничиваем максимальный размер */
  max-height: 40px; /* Ограничиваем максимальный размер */
  transition: transform 0.3s ease, filter 0.3s ease;
}

.arrow-icon img {
  width: 100%;
  height: 100%;
  object-fit: contain; /* Сохраняем пропорции изображения */
}

.square-container:hover .arrow-icon {
  transform: rotate(-180deg);
  filter: brightness(0) saturate(100%);
}

.label {
  position: absolute;
  bottom: 10%; /* Адаптивное позиционирование */
  left: 5%; /* Адаптивное позиционирование */
  color: rgba(255, 126, 7, 1);
  transition: color 0.3s, transform 0.3s;
}

.square-container:hover .label {
  color: black;
  transform: translateX(5px);
}
</style>