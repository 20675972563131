import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "profile-container" }
const _hoisted_2 = { class: "main-container" }
const _hoisted_3 = { class: "left-column" }
const _hoisted_4 = { class: "profile-photo" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "right-column" }
const _hoisted_7 = { class: "profile-info" }
const _hoisted_8 = { class: "profile-row eighteen-font-500w" }
const _hoisted_9 = { class: "profile-row eighteen-font-500w" }
const _hoisted_10 = { class: "profile-row eighteen-font-500w" }
const _hoisted_11 = { class: "age" }
const _hoisted_12 = { class: "profile-row eighteen-font-500w" }
const _hoisted_13 = { class: "rating" }
const _hoisted_14 = { class: "bottom-section" }
const _hoisted_15 = { class: "about-author" }
const _hoisted_16 = { class: "description" }
const _hoisted_17 = { class: "country-overlay" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("img", {
            src: _ctx.authorInfo.authorPhotoTempUrl || '/default-avatar.png',
            alt: "Author Photo"
          }, null, 8, _hoisted_5),
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.addToFavorites && _ctx.addToFavorites(...args))),
            class: "favorite-button eighteen-font-400w"
          }, "В избранное")
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _cache[1] || (_cache[1] = _createElementVNode("span", { class: "label" }, "Псевдоним:", -1)),
            _createElementVNode("span", null, _toDisplayString(_ctx.authorInfo.pseudonym || 'Не указан'), 1)
          ]),
          _createElementVNode("div", _hoisted_9, [
            _cache[2] || (_cache[2] = _createElementVNode("span", { class: "label" }, "Имя:", -1)),
            _createElementVNode("span", null, _toDisplayString(_ctx.authorInfo.name || 'Не указано'), 1)
          ]),
          _createElementVNode("div", _hoisted_10, [
            _cache[3] || (_cache[3] = _createElementVNode("span", { class: "label" }, "Возраст:", -1)),
            _cache[4] || (_cache[4] = _createTextVNode()),
            _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.authorInfo.age || 'Не указан'), 1)
          ]),
          _createElementVNode("div", _hoisted_12, [
            _cache[5] || (_cache[5] = _createElementVNode("span", { class: "label" }, "Рейтинг:", -1)),
            _cache[6] || (_cache[6] = _createTextVNode()),
            _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.authorInfo.rating || 'Не указан'), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("div", _hoisted_15, [
            _cache[7] || (_cache[7] = _createElementVNode("div", { class: "eighteen-font-400w" }, "Об исполнителе", -1)),
            _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.authorInfo.description || 'Нет информации'), 1)
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.authorInfo.country || ''), 1)
  ]))
}