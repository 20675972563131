import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "popular-genres" }
const _hoisted_2 = { class: "BestGenresPosts" }
const _hoisted_3 = {
  key: 0,
  class: "split-container"
}
const _hoisted_4 = { class: "eighteen-font-400w CountOfGenres" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "GenreName" }
const _hoisted_7 = { class: "eighteen-font-400w" }
const _hoisted_8 = { href: '/здесь будут ссылки' }
const _hoisted_9 = { class: "Subgenres eighteen-font-400w" }
const _hoisted_10 = { href: '/здесь будут ссылки' }
const _hoisted_11 = { class: "other-genre-posts" }
const _hoisted_12 = ["onMouseover"]
const _hoisted_13 = { class: "CountOfGenres" }
const _hoisted_14 = { class: "GenreName" }
const _hoisted_15 = { class: "eighteen-font-400w" }
const _hoisted_16 = { href: '/здесь будут ссылки' }
const _hoisted_17 = { class: "eighteen-font-400w Subgenres" }
const _hoisted_18 = { href: '/здесь будут ссылки' }
const _hoisted_19 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QuarterSquare = _resolveComponent("QuarterSquare")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.genres.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", {
              class: "popular-genre GenrePost",
              onMouseover: _cache[0] || (_cache[0] = ($event: any) => (_ctx.hoverGenre = _ctx.genres[0])),
              onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.hoverGenre = null))
            }, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("i", null, "(" + _toDisplayString(_ctx.genres[0].subgenres.length) + ")", 1)
              ]),
              _createElementVNode("img", {
                src: _ctx.genres[0].cover || '/media/images/mockPhotos/temp_genre_cover.jpg',
                alt: 'Обложка жанра не загружена!',
                class: "centered-image first-image"
              }, null, 8, _hoisted_5),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("a", _hoisted_8, _toDisplayString(_ctx.genres[0].name), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_9, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.genres[0].subgenres, (subgenre) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: subgenre.name
                  }, [
                    _createElementVNode("a", _hoisted_10, _toDisplayString(subgenre.name), 1)
                  ]))
                }), 128))
              ])
            ], 32),
            _createElementVNode("div", _hoisted_11, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.genres.slice(1, 4), (genre, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index,
                  class: "post GenrePost",
                  onMouseover: ($event: any) => (_ctx.hoverGenre = genre),
                  onMouseleave: _cache[2] || (_cache[2] = ($event: any) => (_ctx.hoverGenre = null))
                }, [
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("i", null, "(" + _toDisplayString(genre.subgenres.length) + ")", 1)
                  ]),
                  _createElementVNode("div", _hoisted_14, [
                    _createElementVNode("div", _hoisted_15, [
                      _createElementVNode("a", _hoisted_16, _toDisplayString(genre.name), 1)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_17, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(genre.subgenres, (subgenre) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: subgenre.name
                      }, [
                        _createElementVNode("a", _hoisted_18, _toDisplayString(subgenre.name), 1)
                      ]))
                    }), 128))
                  ])
                ], 40, _hoisted_12))
              }), 128)),
              _createElementVNode("div", {
                class: "post GenrePost",
                onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.goToAllGenres && _ctx.goToAllGenres(...args)))
              }, [
                _createVNode(_component_QuarterSquare, { label: "Все жанры" })
              ])
            ])
          ]))
        : (_openBlock(), _createElementBlock("p", _hoisted_19, "Загрузка жанров..."))
    ])
  ]))
}