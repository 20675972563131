<template>
  <div class="difficulty">
    <span
        v-for="star in 5"
        :key="star"
        :class="{
        'star': true,
        'orange-star': star <= rating,
        'gray-star': star > rating
      }"
    ></span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    rating: {
      type: Number,
      required: true
    }
  }
});
</script>

<style scoped>
.difficulty {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.star {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin: 0 2px;
}

.orange-star {
  background-color: rgba(255, 126, 7, 1);
}

.gray-star {
  background-color: rgba(136, 136, 136, 1);
}
</style>