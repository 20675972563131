<template>
  <div class="container">
    <Snackbar :color="'red'" :show="showError" :message="errorMessage" @update:show="showError = $event"/>
    <Snackbar :color="'green'" :show="showSuccess" :message="successMessage" @update:show="showSuccess = $event"/>

    <div class="top-bar">
      <div class="step-section">
        <span class="step-display">{{ currentStepTitle }}</span>
      </div>
      <div class="button-section">
        <button
            @click="goToPreviousStep"
            :disabled="isFirstStep"
            class="back-button"
        >
          Назад
        </button>
        <button
            @click="goToNextStep"
            :disabled="!isNextStepActive"
            class="forward-button"
        >
          {{ isLastStep ? 'Сохранить' : 'Далее' }}
        </button>
      </div>
    </div>

    <div class="form-section">
      <div class="input-block">
        <div v-if="currentStep === 0">
          <div class="form-group">
            <input
                type="text"
                v-model="authorsSearchQuery"
                placeholder="Исполнитель"
                @input="onAuthorInputChange"
                class="search-field"
            />
            <ul v-if="authors.length" class="author-list">
              <li
                  v-for="author in authors"
                  :key="author.id"
                  @click="selectAuthor(author)"
                  class="author-item"
              >
                {{ author.pseudonym }}
              </li>
            </ul>
            <button
                v-if="!authors.length && !selectedAuthor && authorsSearchQuery"
                @click="openAuthorModal"
                class="create-author-button"
            >
              Создать автора
            </button>
          </div>

          <div class="form-group">
            <input
                type="text"
                v-model="albumsSearchQuery"
                placeholder="Альбом"
                :disabled="!selectedAuthor"
                @input="onAlbumInputChange"
                class="album-field"
            />
            <ul v-if="albums.length" class="album-list">
              <li
                  v-for="album in albums"
                  :key="album.id"
                  @click="selectAlbum(album)"
                  class="album-item"
              >
                {{ album.name }}
              </li>
            </ul>
            <button
                v-if="selectedAuthor && !albums.length && !selectedAlbum && albumsSearchQuery"
                @click="openAlbumModal"
                class="create-album-button"
            >
              Создать альбом
            </button>
          </div>

          <div class="form-group">
            <input
                type="text"
                v-model="newSong.song.name"
                placeholder="Название песни"
                class="song-field"
            />
          </div>

          <div class="form-group">
            <input
                type="number"
                v-model="newSong.song.tempoBPM"
                placeholder="Темп (bpm)"
                class="song-field"
            />
          </div>

          <div class="form-group">
            <select
                v-model="newSong.song.subgenreId"
                class="song-field"
                :disabled="!formattedSubgenres.length"
            >
              <option value="" disabled>Жанр</option>
              <option
                  v-for="subgenre in formattedSubgenres"
                  :key="subgenre.id"
                  :value="subgenre.id"
              >
                {{ subgenre.name }}
              </option>
            </select>
          </div>

          <div class="form-group">
            <input
                type="text"
                v-model="newSong.song.difficultInStars"
                placeholder="Уровень сложности"
                @input="validateAndCleanStars"
                class="song-field"
            />
            <span v-if="difficultInStarsError" class="error-message">
              {{ difficultInStarsError }}
            </span>
          </div>

          <div class="form-group">
            <input
                type="text"
                v-model="newSong.song.sampleYoutubeLink"
                placeholder="Ссылка на кавер"
                class="song-field"
            />
          </div>

          <ImageUploadComponent
              :title="'Обложка песни'"
              :description="'Максимальное разрешение: 1500 x 1500. Формат: PNG, JPEG, JPG.'"
              v-model=newSong.photo
          />
        </div>

        <div v-if="currentStep === 1">
          <div class="form-group">
            <textarea
                v-model="newSong.song.description"
                placeholder="Описание песни"
                class="song-field"
            ></textarea>
          </div>

          <div class="button-group">
            <button @click="addSongLine" class="add-remove-button">+</button>
            <button @click="removeSongLine" class="add-remove-button">-</button>
          </div>

          <form @submit.prevent="saveSong">
            <div
                v-for="(line, index) in newSongLines"
                :key="index"
                class="line-row"
            >
              <div class="block-selector-container">
                <button
                    type="button"
                @click="toggleSongBlock(index)"
                class="block-selector"
                >
                {{ line.songBlock }}
                </button>

              </div>
              <div class="chords-editor-container">
                <ChordsEditor
                    :line="line"
                    :index="index"
                    @update-chords="updateChordPositions(line, $event)"
                />
                <input
                    v-model="line.line"
                    placeholder="Строка текста песни"
                    class="song-line-field"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <AuthorModalForm/>
    <AlbumModalForm/>
  </div>
</template>


<script setup lang="ts">
import {computed, onMounted, ref} from 'vue';
import ChordsEditor from "@/components/add-song/ChordsEditor.vue";
import Snackbar from "@/components/common/Snackbar.vue";
import {
  addSongLine,
  albums,
  albumsSearchQuery,
  authors,
  authorsSearchQuery,
  currentStep,
  currentStepTitle,
  errorMessage,
  fetchSongBlocks,
  fetchSubgenres,
  genres,
  goToNextStep,
  goToPreviousStep,
  isFirstStep,
  isLastStep,
  isNextStepActive,
  newSong,
  newSongLines,
  onAlbumInputChange,
  onAuthorInputChange,
  openAlbumModal,
  openAuthorModal,
  removeSongLine,
  saveSong,
  selectAlbum,
  selectAuthor,
  selectedAlbum,
  selectedAuthor,
  showError,
  showSuccess,
  successMessage,
  toggleSongBlock,
  updateChordPositions
} from "@/components/add-song/modal/AddSongFormStates";
import AuthorModalForm from "@/components/add-song/modal/AuthorModalForm.vue";
import AlbumModalForm from "@/components/add-song/modal/AlbumModalForm.vue";
import ImageUploadComponent from "@/components/common/ImageUploadComponent.vue";

onMounted(() => {
  fetchSubgenres();
  fetchSongBlocks()
});

const difficultInStarsError = ref<string | null>(null);

const validateAndCleanStars = () => {
  let value = newSong.value.song.difficultInStars;
  const cleanedValue = value.replace(/[^*]/g, '');
  if (cleanedValue !== value) {
    newSong.value.song.difficultInStars = cleanedValue;
  }
  difficultInStarsError.value = cleanedValue.length >= 1 && cleanedValue.length <= 5 ? null : 'Поле должно содержать от 1 до 5 звездочек';
};

const formattedSubgenres = computed(() => {
  if (!genres.value.length) {
    console.error("Данные genres не загружены");
    return [];
  }
  return genres.value.flatMap((genre) => {
    if (!genre.subgenres || !genre.subgenres.length) {
      console.warn(`Нет поджанров для жанра: ${genre.name}`);
      return [];
    }
    return genre.subgenres.map((subgenre) => ({
      id: subgenre.id,
      name: subgenre.name
    }));
  });
});
</script>

<style scoped>
/* Общие стили */
.container {
  max-width: 1760px;
  margin: 0 auto;
  background-color: #121212;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  height: 70px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.step-section {
  flex-grow: 1;
}

.button-section {
  display: flex;
  gap: 10px;
}

.form-section {
  padding: 20px;
}

.input-block {
  width: 60%;
  margin: 0 auto;
}

/* Элементы форм */
.form-group {
  margin-bottom: 1rem;
}

input, select, textarea {
  width: 100%;
  padding: 12px;
  background-color: #2c2c2c;
  border: 1px solid #444;
  border-radius: 4px;
  color: white;
  font-size: 16px;
}

select {
  appearance: none;
  background-image: url("data:image/svg+xml;charset=US-ASCII,<svg...></svg>");
  background-repeat: no-repeat;
  background-position: right 10px center;
}

label {
  display: block;
  margin-bottom: 8px;
  color: #fff;
}

/* Списки */
.author-list, .album-list {
  list-style: none;
  padding: 0;
  margin: 10px 0;
  max-height: 300px;
  overflow-y: auto;
  background-color: #2c2c2c;
  border: 1px solid #444;
  border-radius: 4px;
}

.author-item, .album-item {
  padding: 12px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.author-item:hover, .album-item:hover {
  background-color: #444;
}

/* Кнопки */
button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.forward-button {
  background-color: #ff6600;
  color: white;
}

.back-button {
  background-color: #6c757d;
  color: white;
}

.create-author-button,
.create-album-button {
  background-color: #444;
  color: white;
  margin: 10px 0;
}

.block-selector {
  background-color: #2c2c2c;
  color: #ff6600;
  border: 1px solid #444;
  width: 100px;
  height: 50px;
}

.add-remove-button {
  background-color: #333;
  color: #ff6600;
  width: 50px;
  height: 50px;
  margin: 0 5px;
}

/* Преью изображения */
.photo-preview {
  width: 150px;
  height: 150px;
  border-radius: 8px;
  margin-top: 10px;
  object-fit: cover;
}

/* Строки с аккордами */
.line-row {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.chords-editor-container {
  flex-grow: 1;
}

.error-message {
  color: #ff4444;
  font-size: 0.875rem;
  margin-top: 4px;
}
</style>