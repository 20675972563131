import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "modal-container" }

import ImageUploadComponent from "@/components/common/ImageUploadComponent.vue";
import albumApi from "@/api/AlbumApi";
import { AxiosError } from "axios";
import {
  albums,
  albumsSearchQuery,
  errorMessage,
  isAlbumModalOpen,
  newAlbum,
  newSong,
  selectedAlbum,
  showError,
  showSuccess,
  successMessage
} from "@/components/add-song/modal/AddSongFormStates";
import DatePicker from "@vuepic/vue-datepicker";


export default /*@__PURE__*/_defineComponent({
  __name: 'AlbumModalForm',
  setup(__props) {

const saveAlbum = async () => {
  try {
    const response = await albumApi.saveAlbum(newAlbum.value);
    if (response && response.name) {
      albumsSearchQuery.value = response.name;
      selectedAlbum.value = {
        ...newAlbum.value.album,
        id: response.id,
        name: response.name,
      };
      newSong.value.song.albumName = response.name;
      albums.value = [];
    }
    successMessage.value = "Альбом успешно создан";
    showSuccess.value = true;
    closeAlbumModal();
  } catch (error) {
    const axiosError = error as AxiosError<ApiErrorResponse>;
    errorMessage.value = axiosError.response?.data?.message || "Ошибка при создании альбома.";
    showError.value = true;
    console.error("Ошибка при создании альбома:", error);
  }
};

const closeAlbumModal = () => {
  isAlbumModalOpen.value = false;
};

return (_ctx: any,_cache: any) => {
  return (_unref(isAlbumModalOpen))
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "modal-overlay",
        onClick: _withModifiers(closeAlbumModal, ["self"])
      }, [
        _createElementVNode("div", _hoisted_1, [
          _cache[5] || (_cache[5] = _createElementVNode("div", { class: "modal-title" }, "Создание нового альбома", -1)),
          _createElementVNode("form", {
            onSubmit: _withModifiers(saveAlbum, ["prevent"]),
            class: "modal-form"
          }, [
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(newAlbum).album.name) = $event)),
              placeholder: "Название альбома",
              class: "input-field"
            }, null, 512), [
              [_vModelText, _unref(newAlbum).album.name]
            ]),
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(newAlbum).album.description) = $event)),
              placeholder: "Описание альбома",
              class: "input-field"
            }, null, 512), [
              [_vModelText, _unref(newAlbum).album.description]
            ]),
            _createVNode(_unref(DatePicker), {
              modelValue: _unref(newAlbum).album.release,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(newAlbum).album.release) = $event)),
              placeholder: "Дата релиза",
              class: "input-field"
            }, null, 8, ["modelValue"]),
            _createVNode(ImageUploadComponent, {
              title: 'Обложка альбома',
              description: 'Максимальное разрешение: 1500 x 1500. Формат: PNG, JPEG, JPG.',
              modelValue: _unref(newAlbum).photo,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(newAlbum).photo) = $event))
            }, null, 8, ["modelValue"]),
            _cache[4] || (_cache[4] = _createElementVNode("button", {
              type: "submit",
              class: "save-button"
            }, "Сохранить", -1))
          ], 32)
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})