import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  class: "grid-container"
}
const _hoisted_3 = { class: "grid-content GenrePost" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "GenreName" }
const _hoisted_6 = { class: "eighteen-font-400w" }
const _hoisted_7 = ["href"]
const _hoisted_8 = { class: "Subgenres" }
const _hoisted_9 = ["href"]
const _hoisted_10 = ["id", "onMouseover"]
const _hoisted_11 = { class: "grid-content GenrePost" }
const _hoisted_12 = { class: "GenreName" }
const _hoisted_13 = { class: "eighteen-font-400w" }
const _hoisted_14 = ["href"]
const _hoisted_15 = { class: "Subgenres" }
const _hoisted_16 = ["href"]
const _hoisted_17 = ["id", "onMouseover"]
const _hoisted_18 = { class: "grid-content GenrePost" }
const _hoisted_19 = { class: "GenreName" }
const _hoisted_20 = { class: "eighteen-font-400w" }
const _hoisted_21 = ["href"]
const _hoisted_22 = { class: "Subgenres" }
const _hoisted_23 = ["href"]
const _hoisted_24 = { class: "grid-content GenrePost" }
const _hoisted_25 = ["src"]
const _hoisted_26 = { class: "GenreName" }
const _hoisted_27 = { class: "eighteen-font-400w" }
const _hoisted_28 = ["href"]
const _hoisted_29 = { class: "Subgenres" }
const _hoisted_30 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.genres.length === 0)
      ? (_openBlock(), _createElementBlock("p", _hoisted_1, "Загрузка жанров..."))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", {
            class: "grid-item large",
            id: "genre-1",
            onMouseover: _cache[0] || (_cache[0] = ($event: any) => (_ctx.hoverGenre = _ctx.genres[0])),
            onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.hoverGenre = null))
          }, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("img", {
                src: _ctx.genres[0].cover || '/media/images/mockPhotos/temp_genre_cover.jpg',
                alt: "Обложка жанра",
                class: "centered-image first-image"
              }, null, 8, _hoisted_4),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("a", {
                    href: '/genre/' + _ctx.genres[0].name
                  }, _toDisplayString(_ctx.genres[0].name), 9, _hoisted_7)
                ])
              ]),
              _createElementVNode("div", _hoisted_8, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.genres[0].subgenres, (subgenre) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: subgenre.name
                  }, [
                    _createElementVNode("a", {
                      href: '/subgenre/' + subgenre.name
                    }, _toDisplayString(subgenre.name), 9, _hoisted_9)
                  ]))
                }), 128))
              ])
            ])
          ], 32),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.genres.slice(1, 5), (genre, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: genre.name,
              class: "grid-item small",
              id: 'genre-' + (index + 2),
              onMouseover: ($event: any) => (_ctx.hoverGenre = genre),
              onMouseleave: _cache[2] || (_cache[2] = ($event: any) => (_ctx.hoverGenre = null))
            }, [
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("a", {
                      href: '/genre/' + genre.name
                    }, _toDisplayString(genre.name), 9, _hoisted_14)
                  ])
                ]),
                _createElementVNode("div", _hoisted_15, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(genre.subgenres, (subgenre) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: subgenre.name
                    }, [
                      _createElementVNode("a", {
                        href: '/subgenre/' + subgenre.name
                      }, _toDisplayString(subgenre.name), 9, _hoisted_16)
                    ]))
                  }), 128))
                ])
              ])
            ], 40, _hoisted_10))
          }), 128)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.genres.slice(5, 9), (genre, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: genre.name,
              class: "grid-item small",
              id: 'genre-' + (index + 6),
              onMouseover: ($event: any) => (_ctx.hoverGenre = genre),
              onMouseleave: _cache[3] || (_cache[3] = ($event: any) => (_ctx.hoverGenre = null))
            }, [
              _createElementVNode("div", _hoisted_18, [
                _createElementVNode("div", _hoisted_19, [
                  _createElementVNode("div", _hoisted_20, [
                    _createElementVNode("a", {
                      href: '/genre/' + genre.name
                    }, _toDisplayString(genre.name), 9, _hoisted_21)
                  ])
                ]),
                _createElementVNode("div", _hoisted_22, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(genre.subgenres, (subgenre) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: subgenre.name
                    }, [
                      _createElementVNode("a", {
                        href: '/subgenre/' + subgenre.name
                      }, _toDisplayString(subgenre.name), 9, _hoisted_23)
                    ]))
                  }), 128))
                ])
              ])
            ], 40, _hoisted_17))
          }), 128)),
          _createElementVNode("div", {
            class: "grid-item large",
            id: "genre-10",
            onMouseover: _cache[4] || (_cache[4] = ($event: any) => (_ctx.hoverGenre = _ctx.genres[9])),
            onMouseleave: _cache[5] || (_cache[5] = ($event: any) => (_ctx.hoverGenre = null))
          }, [
            _createElementVNode("div", _hoisted_24, [
              _createElementVNode("img", {
                src: _ctx.genres[9].cover || '/media/images/mockPhotos/temp_genre_cover.jpg',
                alt: "Обложка жанра",
                class: "centered-image first-image"
              }, null, 8, _hoisted_25),
              _createElementVNode("div", _hoisted_26, [
                _createElementVNode("div", _hoisted_27, [
                  _createElementVNode("a", {
                    href: '/genre/' + _ctx.genres[9].name
                  }, _toDisplayString(_ctx.genres[9].name), 9, _hoisted_28)
                ])
              ]),
              _createElementVNode("div", _hoisted_29, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.genres[9].subgenres, (subgenre) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: subgenre.name
                  }, [
                    _createElementVNode("a", {
                      href: '/subgenre/' + subgenre.name
                    }, _toDisplayString(subgenre.name), 9, _hoisted_30)
                  ]))
                }), 128))
              ])
            ])
          ], 32)
        ]))
  ]))
}