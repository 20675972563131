import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, vModelDynamic as _vModelDynamic, normalizeClass as _normalizeClass, vModelCheckbox as _vModelCheckbox, createTextVNode as _createTextVNode, withModifiers as _withModifiers, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form-group" }
const _hoisted_2 = { class: "form-group" }
const _hoisted_3 = { class: "password-container" }
const _hoisted_4 = ["type"]
const _hoisted_5 = { class: "checkbox-container" }
const _hoisted_6 = { class: "custom-switch" }

import { ref } from 'vue';
import Snackbar from '@/components/common/Snackbar.vue';
import authApi from "@/api/AuthApi";
import { CookieManager } from "@/api/base/CookieManager";
import router from "@/router/router";
import { AxiosError } from "axios";
import {useAuthStore} from "@/store/authStore";


export default /*@__PURE__*/_defineComponent({
  __name: 'LoginForm',
  setup(__props) {

const username = ref<string>('');
const password = ref<string>('');
const showPassword = ref<boolean>(false);
const rememberMe = ref<boolean>(false);

const showError = ref<boolean>(false);
const showSuccess = ref<boolean>(false);
const errorMessage = ref<string>('');
const successMessage = ref<string>('');

const authStore = useAuthStore();

const login = async (): Promise<void> => {
  try {
    const { token } = await authApi.signInUser({ username: username.value, password: password.value });

    if (token) {
      handleSuccessfulLogin(token);
    }
  } catch (error: unknown) {
    handleError(error, 'Неизвестная ошибка. Попробуйте снова.');
  }
};

const handleSuccessfulLogin = (token: string): void => {
  CookieManager.setCookie('token', token);
  successMessage.value = 'Вход выполнен успешно';
  showSuccess.value = true;

  // Обновляем состояние аутентификации
  authStore.checkSession();

  setTimeout(() => {
    router.push({ name: 'home' });
  }, 1000);
};

const handleError = (error: unknown, defaultMessage: string): void => {
  const axiosError = error as AxiosError<ApiErrorResponse>;
  errorMessage.value = axiosError.response?.data?.message || defaultMessage;
  showError.value = true;
};

const goToRegister = (): void => {
  router.push({ name: 'register' });
};

const togglePassword = (): void => {
  showPassword.value = !showPassword.value;
};

const handleBlur = (field: string): void => {
  if (!username.value && field === 'username') username.value = '';
  if (!password.value && field === 'password') password.value = '';
};

const handleFocus = (field: string): void => {
  if (field === 'username') username.value = '';
  if (field === 'password') password.value = '';
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[15] || (_cache[15] = _createElementVNode("div", null, "Пожалуйста, авторизуйтесь в своем профиле или создайте новый", -1)),
    _createVNode(Snackbar, {
      color: "red",
      show: showError.value,
      "onUpdate:show": _cache[0] || (_cache[0] = ($event: any) => ((showError).value = $event)),
      message: errorMessage.value
    }, null, 8, ["show", "message"]),
    _createVNode(Snackbar, {
      color: "green",
      show: showSuccess.value,
      "onUpdate:show": _cache[1] || (_cache[1] = ($event: any) => ((showSuccess).value = $event)),
      message: successMessage.value
    }, null, 8, ["show", "message"]),
    _createElementVNode("form", {
      onSubmit: _withModifiers(login, ["prevent"])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _withDirectives(_createElementVNode("input", {
          type: "text",
          id: "username",
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((username).value = $event)),
          placeholder: "Номер телефона",
          onBlur: _cache[3] || (_cache[3] = ($event: any) => (handleBlur('username'))),
          onFocus: _cache[4] || (_cache[4] = ($event: any) => (handleFocus('username'))),
          required: ""
        }, null, 544), [
          [_vModelText, username.value]
        ])
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _withDirectives(_createElementVNode("input", {
            type: showPassword.value ? 'text' : 'password',
            id: "password",
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((password).value = $event)),
            placeholder: "Пароль",
            onBlur: _cache[6] || (_cache[6] = ($event: any) => (handleBlur('password'))),
            onFocus: _cache[7] || (_cache[7] = ($event: any) => (handleFocus('password'))),
            required: ""
          }, null, 40, _hoisted_4), [
            [_vModelDynamic, password.value]
          ]),
          _createElementVNode("div", {
            class: "eye-container",
            onClick: togglePassword
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(['eye', showPassword.value ? 'eye-closed' : 'eye-open'])
            }, null, 2)
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("label", _hoisted_6, [
            _withDirectives(_createElementVNode("input", {
              type: "checkbox",
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((rememberMe).value = $event)),
              class: "switch-input"
            }, null, 512), [
              [_vModelCheckbox, rememberMe.value]
            ]),
            _cache[9] || (_cache[9] = _createElementVNode("span", { class: "switch-slider" }, null, -1)),
            _cache[10] || (_cache[10] = _createTextVNode(" Запомнить меня "))
          ]),
          _cache[11] || (_cache[11] = _createElementVNode("a", {
            href: "#",
            class: "forgot-password"
          }, "Забыли пароль?", -1))
        ])
      ]),
      _cache[12] || (_cache[12] = _createElementVNode("button", {
        type: "submit",
        class: "primary-button"
      }, "Войти", -1)),
      _createElementVNode("button", {
        type: "button",
        class: "secondary-button",
        onClick: goToRegister
      }, "Создать новый профиль"),
      _cache[13] || (_cache[13] = _createElementVNode("div", { class: "or" }, "Или", -1)),
      _cache[14] || (_cache[14] = _createElementVNode("button", {
        type: "button",
        class: "vk-button"
      }, "Войти с помощью VK", -1))
    ], 32)
  ], 64))
}
}

})