import {BaseApi} from "@/api/base/BaseApi";

const API_PREFIX = '/backend';

export class GenreApi {
    private client: BaseApi;

    constructor() {
        this.client = new BaseApi();
    }

    public async fetchPopularGenres(sortDirection: string, limit: number): Promise<BackendT["GenreResponse"][]> {
        try {
            return this.client.get<BackendT["GenreResponse"][]>(API_PREFIX + '/genre/popular', {
                params: {
                    sortDirection,
                    limit
                },
                headers: {
                    'Content-Type': 'application/json',
                }
            });
        } catch (error) {
            throw new Error(`An error occurred while fetching popular genres: ${error}`);
        }
    }

    public async fetchAllSongGenres(): Promise<BackendT["GenreResponse"][]> {
        try {
            return this.client.get<BackendT["GenreResponse"][]>(API_PREFIX + '/genre/all', {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
        } catch (error) {
            throw new Error(`An error occurred while fetching all song genres: ${error}`);
        }
    }

    public async fetchAllSubgenreNames(): Promise<string []> {
        try {
            return this.client.get<string []>(API_PREFIX + '/genre/subgenre-names', {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
        } catch (error) {
            throw new Error(`An error occurred while fetching all song genres: ${error}`);
        }
    }
}

export default new GenreApi();
