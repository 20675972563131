import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "message" }

import { ref, watch } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'Snackbar',
  props: {
  color: {
    type: String,
    required: true
  },
  show: {
    type: Boolean,
    required: true
  },
  message: {
    type: String,
    required: true
  }
},
  emits: ['update:show'],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const internalShow = ref(props.show);
let timeoutId: number;

watch(() => props.show, (newVal) => {
  internalShow.value = newVal;
  if (newVal) {
    timeoutId = setTimeout(() => {
      close();
    }, 6000);
  }
});

watch(internalShow, (newVal) => {
  emit('update:show', newVal);
});

const close = () => {
  internalShow.value = false;
  clearTimeout(timeoutId);
};

return (_ctx: any,_cache: any) => {
  return (internalShow.value)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "snackbar",
        style: _normalizeStyle({ backgroundColor: __props.color })
      }, [
        _createElementVNode("div", _hoisted_1, _toDisplayString(__props.message), 1),
        _createElementVNode("button", {
          class: "close-button",
          onClick: close
        }, " Закрыть ")
      ], 4))
    : _createCommentVNode("", true)
}
}

})